import { v4 as uuidv4 } from 'uuid';
const apiError = require('./apiError');
const axios = require('axios');
const apiUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_NAME}`;

function getAuthObject(type, personToken = '', customerId = '') {
    let authObject = {
        type: type,
        params: {
            id: uuidv4()
        }
    }

    if ((type === 'person' || 'admin') && personToken){
      authObject.params.personToken = personToken;
    }

    if (type === 'admin'){
      authObject.params.customerId = customerId;
    }

    return authObject;
}

/**
 * request object for api call
 * @param { string } methodName
 * @param { object } params
 * @returns { object }
 */
 function createRequestObject(methodName, params) {
    let requestObject = {
        jsonrpc: "2.0",
        id: uuidv4(),
        method: methodName,
        params: {
          id: uuidv4(),
          auth: getAuthObject('application')
        }
    }
    if (params) {
        requestObject.params = Object.assign({}, requestObject.params, params);
    }
    return requestObject;
}

export default {
    /**
     * @description API axios http request wrapper
     * @param { string } methodName
     * @param { object } params
     * @returns { object }
     */
    request: async function (methodName, params) {
        let requestObject = createRequestObject(methodName, params);
        const response = await axios.post(apiUrl, requestObject);

        if (apiError.match(response)) {
            return Promise.reject(apiError.construct(response));
        }

        return Promise.resolve(response);
    },
    getAuthObject: getAuthObject
}
