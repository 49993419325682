<template>
  <div class="icon-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 40 40"
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g
                transform="translate(-620 -235) translate(265 215) translate(272 20) translate(83)"
              >
                <path d="M0 0L40 0 40 40 0 40z" />
                <circle cx="20" cy="20" r="16.667" :stroke="color" />
                <path
                  :fill="color"
                  d="M20.833 7.5V20h12.5v1.667l-12.5-.001v12.5h-1.666v-12.5h-12.5V20h12.499V7.5h1.667z"
                  transform="rotate(45 20 20.833)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CrossIconWithCircle",

  props: {
    color: {
      type: String,
      required: false,
      default: "#757575",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/styles/iconHoverEffectStyle";
</style>
