import moment from 'moment';
import _ from 'lodash';
import { NOTIFICATION_TYPES } from '@packages/constants';
// import { COMPANY_INFORMATION_ITEMS } from './constants/companyInformationObjectConstants';
import { COMPANY_INFORMATION_ITEMS } from '@packages/constants';
import he from 'he';
import {
  tooManyRequestsConstants,
  COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS
} from '@packages/constants';
const {
  MINIMUM_TOKEN_AMOUNT_REQUIRED_TO_CONTINUE_WORKING,
  TOO_MANY_REQUESTS_ERROR_CODES
} = tooManyRequestsConstants;

function defaultSortTable(array, sortValue, orderBy) {
  return array.sort((a, b) => {
    let propertyA = a.columns[sortValue].sortValue;
    let propertyB = b.columns[sortValue].sortValue;

    if (typeof orderBy !== 'undefined' && orderBy === 'desc') {
      return (propertyA > propertyB) ? -1 : (propertyA < propertyB) ? 1 : 0;
    } else {
      return (propertyA < propertyB) ? -1 : (propertyA > propertyB) ? 1 : 0;
    }
  });
}

export const lib = {

  /**
   * It returns if the userData type is Email and not includes placeholder+ in it.
   * @param { Object } userData
   * return { Boolean }
  */
  checkPlaceholderEmail(userData) {
    return userData.type === 'Email' && !userData.data.includes('placeholder+');
  },

  /**
   * arrayDiff returns de difference between two simple arrays
   * @param { Array } a1
   * @param { Array } a1
   * return { Array }
  */
   arrayDiff (a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
  },

  /**
   * b64toBlob converts base64 string into blob
   * @param { String } b64Data
   * @param { String } contentType
   * @param { Integer } sliceSize
   * return { Blob }
  */
  b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];


    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  },

  /**
   *  isArraysEqual checks that two linear arrays equla each other
   *  @param { Array } arr1
   *  @param { Array } arr2
   *  return { Boolean }
  */
  isArraysEqual: function(arr1, arr2) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return false;
    }

    if(arr1.length !== arr2.length) {
      return false;
    }

    for(let i = arr1.length -1; i > -1; i--) {
      if(arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  },

  /**
   * createTooManyRequestsErrorObject calculates how much time is left before the user
   * can login again, according to the token availability, recovery rate and minimum amount required,
   * and then creates the correct notification object.
   * @param { Object } tokenBucket - tokenBucket object. Contains the token availability and recovery rate
   * @returns { Object } error object
  */
  createTooManyRequestsErrorObject (tokenBucket) {
    const ERROR_SCOPE = 'tooManyRequestsError';
    const ERROR_CODE = {
      INFINITE_TIME_TO_RECOVER_TOKENS: 'infiniteTimeToRecoverTokens',
      MINUTES: {
        SINGULAR: 'minuteToRecoverTokens',
        PLURAL: 'minutesToRecoverTokens'
      },
      HOURS: {
        SINGULAR: 'hourToRecoverTokens',
        PLURAL: 'hoursToRecoverTokens'
      }
    };

    const remainingTokens = MINIMUM_TOKEN_AMOUNT_REQUIRED_TO_CONTINUE_WORKING - tokenBucket.available;
    const secondsRemaining = remainingTokens / tokenBucket.rate;
    const minutesRemaining = Math.floor(secondsRemaining / 60) + 1;

    if (minutesRemaining === Infinity) {
      return {
        message: {
          scope: ERROR_SCOPE,
          code: ERROR_CODE.INFINITE_TIME_TO_RECOVER_TOKENS
        }
      };
    } else if (minutesRemaining <= 60) {
      return {
        message: {
          scope: ERROR_SCOPE,
          code: minutesRemaining === 1 ? ERROR_CODE.MINUTES.SINGULAR : ERROR_CODE.MINUTES.PLURAL
        },
        translationParams: { time: minutesRemaining }
      };
    } else {
      const hoursRemaining = Math.floor(minutesRemaining / 60) + 1;

      return {
        message: {
          scope: ERROR_SCOPE,
          code: hoursRemaining === 1 ? ERROR_CODE.HOURS.SINGULAR : ERROR_CODE.HOURS.PLURAL
        },
        translationParams: { time: hoursRemaining }
      };
    }
  },

  /**
   *  createErrorObject creates the error object needed for the $notify method
   *  @param { Object } error - error object
   *  @param { Object } contextObject - object containing the context and if the error is common or not
   *  @returns { Object } constructed error object
  */
  createErrorObj: function(error, contextObject={context: null, includeCommonErrors: false}) {
    if(typeof error === 'string'){
      this.$rollbar.error(error, {payload: error});
    } else {
      const errorCode = typeof error.code === 'function' ? error.code() : error.code;

      let errorObj = {
        type: 'application-error',
        message: {
          code: 'defaultError',
          scope: 'common',
        }
      };

      if (error.scope) {
        if (typeof error.scope === 'function') {
          errorObj.message.scope = error.scope();
        } else {
          errorObj.message.scope = error.scope;
        }
      }

      if (errorCode) {
        errorObj.message.code = errorCode;
      }

      if(contextObject?.context) {
        const hasContext = errorObj?.message?.scope !== 'common' || (errorObj?.message?.scope === 'common' && contextObject?.includeCommonErrors);
        errorObj.message.context = hasContext ? contextObject.context : null;
      }

      if (error.nbError) {
        errorObj.nbError = error.nbError;
        errorObj.type = 'internal-error';

        errorObj.payload = error;
        if (this.hasOwnNestedProperty(error, 'response.config.data')) {
          const requestData = typeof error?.response?.config?.data === 'string' ? { data: error?.response?.config?.data } : JSON.parse(error.response.config.data);

          let filteredRequestData = 'No auth';

          if (this.hasOwnNestedProperty(requestData, 'params.auth.type')) {
            filteredRequestData = JSON.stringify(Object.assign({}, requestData, { params: { auth: { type: requestData.params.auth.type }}}));
          }

          errorObj.payload.response.config.data = filteredRequestData;
        }

        if (errorCode === 0) {
          errorObj.message.code = 'defaultError';
        }
      }

      if (error.displayOptions) {
        errorObj.displayOptions = error.displayOptions;
      }

      if (error.type) {
        errorObj.type = error.type;
      }

      if (TOO_MANY_REQUESTS_ERROR_CODES.includes(errorCode) && error.response.data.error.data.details.tokenBucket) {
        const tooManyRequestsErrorObject = this.createTooManyRequestsErrorObject(error.response.data.error.data.details.tokenBucket);
        errorObj = {
          ...errorObj,
          ...tooManyRequestsErrorObject
        }
      }

      return errorObj;
    }
  },

  /**
   * Extracts a substring between two strings
   * @param { String } string
   * @param { String } start
   * @param { String } end
   * @return { String }
  */
  extractSubstringbetweenStrings(string, start, end) {
    let firstIdx = 0;
    let lastIdx = string.length;
    if(start !== '') {
      firstIdx = string.indexOf(start);
    }

    if(end !== '') {
      lastIdx = string.indexOf(end);
    }

    let subString = string.slice(firstIdx, lastIdx);
    subString = subString.replace(start, '').replace(end, '');

    return subString;
  },

  /**
   * compare two objects
   * @param { object } obj1
   * @param { object } obj2
   * @return { boolean }
  */
  objectDeepCompare: function (obj1, obj2) {
    if (typeof(obj1) !== typeof(obj2)) {
      return false;
    }

    if (typeof(obj1) === "function") {
      return obj1.toString() === obj2.toString();
    }

    if ((obj1 instanceof Object) && (obj2 instanceof Object)) {
      let obj1Keys = Object.keys(obj1);
      if (obj1Keys.length !== Object.keys(obj2).length) {
        return false;
      }
      for (let key of obj1Keys) {
        if (!this.objectDeepCompare(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    }
    return obj1 === obj2;
  },

  /**
   *  emailIsValid validates an email
   *  @param { String } email
   *  return { Boolean }
  */
  emailIsValid: function(email) {
    // Checking that email is valid (@ symbol, extension, symbols, etc)
    /* eslint-disable no-useless-escape */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-ensable no-useless-escape */
    return re.test(String(email).toLowerCase());
  },

  /**
   * formatDate formats a date using moment and the loocale according to the selected language
   * @param { Date } date - date to be formatted
   * @param { String } format - format to be used
   * @returns { String } formatted date
  */
  formatDate: function (date, format) {
    const DEFAULT_LOCALE = 'en-US';

    const now = new Date();
    let locale = localStorage.getItem('nb_locale') || DEFAULT_LOCALE;
    const excludedLocales = localStorage.getItem('nb_excluded_locales') ?
      JSON.parse(localStorage.getItem('nb_excluded_locales')) :
      [];

    if (excludedLocales.includes(locale)) {
      locale = DEFAULT_LOCALE;
    }

    moment.locale(locale.split('-')[0]);

    if (moment(date).isSame(now, 'year')) {
      return moment(new Date(date)).format(format);
    }

    return moment(new Date(date)).format(format);
  },

  /**
   *  formats a date and time using moment and the locale according to the selected language, and user timezone.
   *  @param { Date } date
   *  @param { String } format
   *  return { String } (formatted date)
  */
  formatUTCDateToLocal(date, format) {
    const locale = localStorage.getItem('nb_locale') ? localStorage.getItem('nb_locale').split('-')[0] : null;
    moment.locale(locale);
    return moment.utc(date).local().format(format);
  },

  /**
   *  Gets date to be formatted and also compares it current year to skip the year part if the date already current year
   *  @param { Date } date
   *  return { String } (Formatted date compared to current year - either "DD MM" or "DD MM YYYY")
  */
  getFormattedDateComparedToCurrentYear(date) {
    let formattedDate = this.formatDate(date, 'DD MMM');
    const isDateCurrentYear = moment(date).isSame(new Date(), 'year');
    if (!isDateCurrentYear) {
      formattedDate = this.formatDate(date, 'DD MMM YYYY');
    }

    return formattedDate;
  },


  getFormattedDateWithHour(date) {
    return this.formatDate(date, 'DD MMM YYYY HH:mm:ss');
  },

  /**
   *  getEmail extract the email from the user data
   *  @param { Object } user
   *  return { String } email
  */
  getEmail(user) {
    let email = null;
    user.datas.forEach((d) => {
      if (d.type === 'Email' && !email) {
        email = d.data;
      }
    });

    return email;
  },

  /**
   *  getName extract the name from the user data
   *  @param { Object } user
   *  return { String } name
  */
  getName(user) {
    let name = null;
    user.datas.forEach((d) => {
      if (d.type === 'Name' && !name) {
        name = d.data;
      }
    });

    return name;
  },

  getMultipleValuesArray(user, value) {
    let multipleValuesArray = [];
    user.datas.forEach((d) => {
      if (d.type === value) {
        multipleValuesArray.push(d.data);
      }
    });

    if(multipleValuesArray.length > 1) {
      return multipleValuesArray;
    }else if(multipleValuesArray.length === 1){
      return multipleValuesArray[0];
    }
  },

  /**
   *  hasOwnNestedProperty deepCheck if object has property
   *  @param { propertyPath } string
   *  return { Boolean }
  */
  hasOwnNestedProperty(object, propertyPath) {
    if(!propertyPath)
        return false;

    var properties = propertyPath.split('.');
    var obj = object;

    for (var i = 0; i < properties.length; i++) {
        var prop = properties[i];

        if(!obj || !obj.hasOwnProperty(prop)){
            return false;
        } else {
            obj = obj[prop];
        }
    }

    return true;
  },

  /**
   *  nameIsValid validates an name
   *  @param { String } name
   *  return { Boolean }
  */
  nameIsValid: function(name) {
    // Checking that there are not 2 spaces together
    /* eslint-disable no-useless-escape */
    const re = /[ ]{2,}/;
    /* eslint-ensable no-useless-escape */
    return !re.test(String(name).toLowerCase());
  },

  /**
   *  serialize converts object into a url query string.
   *  @param { Object } obj
  */
  serialize(obj) {
      var str = [];
      for (var p in obj)
          if (obj.hasOwnProperty(p)) {
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
      return str.join("&");
  },

  /**
   *  sortByProperty takes an array of objects and sorts it according to the property passed
   *  as a second parameter.
   *  @param { Array | null } array
   *  @param { String } propertyName
   *  @param { string } orderBy optional 'asc', 'desc'
   *  return { Array } (sorted array)
  */
  sortByProperty: function(array, propertyName, orderBy) {
    if (array && propertyName) {
      return array.sort((a, b) => {
        const propertyA = a[propertyName].toUpperCase();
        const propertyB = b[propertyName].toUpperCase();
        if (typeof orderBy !== 'undefined' && orderBy === 'desc') {
          return (propertyA > propertyB) ? -1 : (propertyA < propertyB) ? 1 : 0;
        } else {
          return (propertyA < propertyB) ? -1 : (propertyA > propertyB) ? 1 : 0;
        }
      });
    }
    return [];
  },

  defaultSortTable: defaultSortTable,

  /**
   *  defaultSortTableAlphabeticalMethod takes an array of objects
   *  that have and sorts it according to the 'value' property of the property passed as aparam.
   *  @param { Array } array
   *  @param { String } sortValue
   *  @param { string } orderBy optional 'asc', 'desc'
   *  return { Array } (sorted array)
  */
   defaultSortTableAlphabeticalMethod(arrayToSort, sortValue, orderBy) {
    let modifiedArrayToSort = arrayToSort.map(sortObject => {
      sortObject.columns[sortValue].sortValue = sortObject.columns[sortValue].value.toUpperCase();
      return sortObject;
    });

    return defaultSortTable(modifiedArrayToSort, sortValue, orderBy);
  },

  sortTableArrayByNumber(arrayToSort, sortValue, orderBy) {
    let modifiedArrayToSort = arrayToSort.map(sortObject => {
      sortObject.columns[sortValue].sortValue = sortObject.columns[sortValue].value;
      return sortObject;
    });

    return defaultSortTable(modifiedArrayToSort, sortValue, orderBy);
  },

  /**
   *  sortTableArrayByDate takes an array of objects
   *  that have and sorts it according to the 'value' of a date property passed as a param.
   *  @param { Array } array
   *  @param { String } sortValue
   *  @param { string } orderBy optional 'asc', 'desc'
   *  return { Array } (sorted array)
  */
  sortTableArrayByDate(arrayToSort, sortValue, orderBy) {
    let modifiedArrayToSort = arrayToSort.map(sortObject => {
      sortObject.columns[sortValue].sortValue = new Date(sortObject.columns[sortValue].date).getTime();
      return sortObject;
    });

    return defaultSortTable(modifiedArrayToSort, sortValue, orderBy);
  },

  /**
   *  removes leading, trailing and double whitespace
   *  @param { string } value
   *  @return { string } value
  */
  trimWhitespace: function(value) {
    if (typeof value === 'string') {
      value = value.replace(/\s+/g,' ').replace(/^\s+|\s+$/,'');
    }
    return value;
  },

  /**
   *  get username from selected user
   *  @param { object } selectedUser object of all the selected user data
   *  @param { string } defaultName string with default if user name is not found
   *  @return { string } name
  */
  getUserNameFromSelectedUser(selectedUser, defaultName) {
    let name = '';
    if (selectedUser) {
      name = selectedUser.datas.filter(ud => ud.type() === 'Name');
      name = (name.length) ? name[0].userData.data : defaultName;
    } else {
      name = defaultName;
    }
    return name;
  },

  /**
   * replaces a variable in curly braces, i.e.: '{name}' with a value - returns entire template
   * @param { string } template
   * @param { array } replacementItems - array of objects with variable and value properties
   * @return { string } template
  */
  replaceTemplateVariables(template, replacementItems) {
    // regEx finds all items surrounded by curly braces
    let regEx = new RegExp(/\{(.*?)\}/g);
    const templateVariableMatches = template.match(regEx);
    if (templateVariableMatches !== null) {
      for (let i = 0; i < templateVariableMatches.length; i++) {
        const trimmedVariable = templateVariableMatches[i].slice(1, -1).toLowerCase();
        for (let x = 0; x < replacementItems.length; x++) {
          if (trimmedVariable === replacementItems[x].variable) {
            template = template.replace(templateVariableMatches[i], replacementItems[x].value, 'g');
          }
        }
      }
    }
    return template;
  },

  /**
   * Changing color either darker or brighter by using Hex and floating point number and returns hex.
   * @param {string} hex color
   * @param { number } Negative number it will make be Darker and positive number will be Brighter. 1 = 100%, -0.1 = -10%
   * @returns {string} hex color
  */
  colorLuminance(hexColor, luminosity) {
    // validate hex string
    hexColor = String(hexColor).replace(/[^0-9a-f]/gi, '');
    if (hexColor.length < 6) {
      hexColor = hexColor[0]+hexColor[0]+hexColor[1]+hexColor[1]+hexColor[2]+hexColor[2];
    }
    luminosity = luminosity || 0;
    // convert to decimal and change luminosity
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hexColor.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * luminosity)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }
    return rgb;
  },

  /**
   * Changing icon background color brighter with adding dynamic opacity and returns rgba with opacity.
   * @param {string} color
   * @param {number} opacityValue
   * @returns {string} opacityAddedColor
  */
  addOpacityToIcon(color, opacityValue) {
    // take hex color with opacity
    const hex = this.colorLuminance(color, 0.1);
    let rgbaColor = '';
    let opacityAddedColor = '';
    // convert hex to rgba
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      let colorParam = hex.substring(1).split('');
      if(colorParam.length < 6){
        colorParam = [colorParam[0], colorParam[0], colorParam[1], colorParam[1], colorParam[2], colorParam[2]];
      }
      colorParam = '0x'+ colorParam.join('');
      rgbaColor = 'rgba('+[(colorParam>>16)&255, (colorParam>>8)&255, colorParam&255].join(',')+',1)';
    }
    // change opacity 1 to 0.opacityValue
    opacityAddedColor = rgbaColor.substring(0,rgbaColor.lastIndexOf(',')+1).concat('0.',opacityValue,')');

    return opacityAddedColor;
  },

  /**
   * Gets stored url quer string and parses it into an object
   * @returns {object} url parameters
   */
  parseStoredUrlParams (rollbarInstance) {
    const decodedURLParams = decodeURIComponent(getCookie('nb_url_params'))
    const urlParams = new URLSearchParams(decodedURLParams);
    let parsedUrlParams = {};
    for(const entry of urlParams.entries()) {
      parsedUrlParams[entry[0]] = entry[1];
    }

    /**
     * We want to be notified if someone has duplicate keys in the url params.
     */
    if (rollbarInstance && urlParams.size !== Object.keys(parsedUrlParams)?.length) {
      rollbarInstance.error('Duplicate keys in url params', {payload: {urlParams: decodedURLParams}});
    }

    return parsedUrlParams;
  },

  /**
   * Gets company information, groups relations with a key, deletes unnecessary ones and returns the flatten object.
   * @param {object} companyInformationObj - companyInformation object
   * @returns {object} flattenedObject - flatten companyInformation
  */
  flattenCompanyInformationObject(companyInformationObj) {
    const flattenedObject = {};
    const blackListForObjectKeys = [
      COMPANY_INFORMATION_ITEMS.SOURCE,
      COMPANY_INFORMATION_ITEMS.SOURCE_DETAILS,
      COMPANY_INFORMATION_ITEMS.MODIFIED,
      COMPANY_INFORMATION_ITEMS.ACCESS,
      COMPANY_INFORMATION_ITEMS.IS_NEW_SEARCH
    ];

    const blackListForObjectsHavingSpecialCases = [
      COMPANY_INFORMATION_ITEMS.RELATIONS,
      COMPANY_INFORMATION_ITEMS.ADDRESS,
      COMPANY_INFORMATION_ITEMS.INDUSTRIES,
      COMPANY_INFORMATION_ITEMS.REGISTRATION,
      COMPANY_INFORMATION_ITEMS.REGISTERED_CAPITAL
    ];

    Object.keys(companyInformationObj).forEach((key) => {
      if (typeof companyInformationObj[key] === 'object' && companyInformationObj[key] !== null && !blackListForObjectsHavingSpecialCases.includes(key) && !blackListForObjectKeys.includes(key)) {
        // TODO this.flattenObject is not a function so this was breaking. Now it passes the unflattened object, we need to reinstate that function
        // Object.assign(flattenedObject, this.flattenObject(companyInformationObj[key]));
        Object.assign(flattenedObject, companyInformationObj[key]);
      } else if(key === COMPANY_INFORMATION_ITEMS.RELATIONS && companyInformationObj[key]) {
        const relations = companyInformationObj[key];

        Object.values(relations).forEach(relation => {
          if(relation?.shareRange?.from && relation?.shareRange?.to){
            const formatDecimalNumbers = /[.,]00$/
            const from = (relation.shareRange.from * 100).toFixed(2).replace(formatDecimalNumbers, "");
            const to = (relation.shareRange.to * 100).toFixed(2).replace(formatDecimalNumbers, "");
            relation.share = `${from}-${to}`;
            delete relation.shareRange;
          }
        });

        let grouppedRelations = _.groupBy(
          relations,
          relation => relation.type ? relation.type : relation.group
        );

        Object.assign(flattenedObject, grouppedRelations);
      } else if(key === COMPANY_INFORMATION_ITEMS.INDUSTRIES) {
        let mappedIndustries = [];

        for(let i = 0; i < companyInformationObj[key].length; i++) {
          mappedIndustries.push(companyInformationObj[key][i].industryCode);
          mappedIndustries.push(companyInformationObj[key][i].description);
        }

        Object.assign(flattenedObject, { industries: mappedIndustries });
      } else if (key === COMPANY_INFORMATION_ITEMS.REGISTERED_CAPITAL) {
        const { amount, currencyCode } = companyInformationObj[key] ?? {};

        Object.assign(flattenedObject, { registeredCapital: `${amount} ${currencyCode}`});
      } else if(!blackListForObjectKeys.includes(key)){
        flattenedObject[key] = companyInformationObj[key];
      }
    });
    if(flattenedObject.registration && flattenedObject.registration.countryCode) {
      delete flattenedObject.registration.countryCode;
    }
    return flattenedObject;
  },

  /**
   * Gets correct notification type based on http status code
   * @param {number} errorCode - http status code
   * @returns {string} notification type
   */
  getNotificationTypeFromHttpStatusCode (errorCode) {
    switch (errorCode) {
    case errorCode >= 500:
      return NOTIFICATION_TYPES.INTERNAL_ERROR;
    case 404:
      return NOTIFICATION_TYPES.USER_INFO;
    default:
      return NOTIFICATION_TYPES.USER_INFO;
    }
  },

  /**
   * Gets company information provider name by id
   * @param {string} providerId - company information provider id
   * @returns {string} company information provider name
   */
  getCompanyInformationProviderName (providerId) {
    return COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS[providerId].name;
  },

  /**
   * goes through the first level of the object and checks if any of the values contains the query string
   * @param {Object} objectToCheck - object to check
   * @param {String} queryString - query string to check
   * @returns {Boolean} true if any of the values contains the query string
   */
  objectContainsValue (objectToCheck, queryString) {
    return Object.values(objectToCheck)
      .some(value => String(value).toLowerCase().includes(queryString));
  },

  /**
   * fileContainsMaliciousHTML checks if the file contains some HTML tags
   * by checking it's content
   * @param { File } file - file to be checked
   * @returns { Promise } - Promise that resolves with the result.
   * True if the file contains HTML tags, false otherwise.
   */
  async fileContainsMaliciousHTML(file) {
    return new Promise((resolve, reject) => {
      const HTML_TAGS_REGEX = /<((a )|(script|iframe|img|link|form|embed|object|video|audio))+.*>|on\w+\s*=\s*["'][^"']*["']/gi;

      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const fileContent = event.target.result;
        const decodedFileContent = he.decode(fileContent);
        resolve(HTML_TAGS_REGEX.test(decodedFileContent));
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
      fileReader.readAsText(file);
    });
  },

  isDateExpired(expiredDate) {
    const date = new Date();
    const now = date.toISOString();

    return expiredDate > now;
  },

  isDateActive(activeDate) {
    const date = new Date();
    const now = date.toISOString();

    return activeDate < now;
  },
};
