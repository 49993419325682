<template>
    <svg :width="width" :height="height" :viewBox="options.viewBox" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" id="a"/>
      </defs>
      <g fill="none" fill-rule="evenodd" opacity=".5">
          <path d="M0 0h24v24H0z"/>
          <mask id="b" fill="#fff">
              <use xlink:href="#a"/>
          </mask>
          <use fill="#000" fill-rule="nonzero" xlink:href="#a"/>
          <g mask="url(#b)" :fill="color">
              <path d="M0 0h24v24H0z"/>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
    name: "LockIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: '#757575'
      },
      width: {
        type: String,
        required: false,
        default: '24'
      },
      height: {
        type: String,
        required: false,
        default: '24'
      },
      options: {
        type: Object,
        required: false,
        default: () => {
            return { viewBox: '0 0 24 24' }
        }
      }
    }
}
</script>

<style>

</style>