<template>
  <div class="icon-container">
    <svg
        :height="height"
        viewBox="0 0 24 24"
        :width="width"
        :fill="color"
    >
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M19.07 4.93l-1.41 1.41C19.1 7.79 20 9.79 20 12c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-4.08 3.05-7.44 7-7.93v2.02C8.16 6.57 6 9.03 6 12c0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41C15.55 9.9 16 10.9 16 12c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V2h-1C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-2.76-1.12-5.26-2.93-7.07z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MonitorDataIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: '#757575'
    },
    width: {
      type: Number,
      required: false,
      default: 24
    },
    height: {
      type: Number,
      required: false,
      default: 24
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/iconHoverEffectStyle';
</style>