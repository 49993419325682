<template>
  <div class="nemid-icon-container">
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path 
          d="M3.26 10.958h11.365a.678.678 0 0 0 .358-.376c.12-.274.18-.446.232-.57.08-.192.729-2.012 3.2-2.012 2.47 0 3.666 1.974 3.58 3.65-.084 1.677-1.315 3.28-3.58 3.28-1.287 0-2.197-.514-2.732-1.542a.56.56 0 0 1 .303-.783.763.763 0 0 1 .857.237c.468.58.992.87 1.572.87 1.066 0 2.22-.923 2.22-2.062 0-1.138-.53-2.298-2.22-2.298-2.16 0-2.088 2.01-2.51 2.47-.282.305-.709.437-1.28.395H6.408v2.094a.706.706 0 1 1-1.412 0v-2.094h-.239c-.767 0-1.389.622-1.389 1.389v.704a.684.684 0 0 1-1.368 0v-2.093c0-.696.564-1.26 1.26-1.26z" 
          :fill="color"
        />
    </g>
  </svg>
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "NemIdIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },
    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },
  }
}
</script>

<style scoped lang="scss">
.nemid-icon-container {
  display: flex;
  justify-content: center;
}
</style>