<template>
  <div class="identity-icon-container">
    <img
      class="icon"
      :width="width"
      :height="height"
      :src="getIdentityIcon(identityType)"
      alt="profile-icon"
    >
  </div>
</template>

<script>
  export default {
    name: "IdentityIcon",

    props: {
      identityType: {
        type: String,
        required: false,
        default: 'unknown'
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      }
    },

    data() {
      return {
        iconLocations: {
          company: '/static/images/company/default-company.svg',
          companyPlaceholder: '/static/images/company/default-company-placeholder.svg',
          person: '/static/images/users/default-account.svg',
          placeholder: '/static/images/users/default-placeholder.svg',
          unknown: '/static/images/users/unknown.svg',
        }
      }
    },

    methods: {
      getIdentityIcon(identityType) {
        return (this.iconLocations[identityType]) ? this.iconLocations[identityType] : this.iconLocations.unknown;
      }
    }
  }
</script>

<style scoped>

</style>