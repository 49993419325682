<template>
  <div class="info-icon-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="info-icon-00"
          d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 9h-2v6h2v-6zm0-4h-2v2h2V7z"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-519 -293) translate(499 155) translate(10 130) translate(10 8)">
                <path d="M0 0L24 0 24 24 0 24z"/>
                <mask :id="maskId" fill="#fff">
                  <use xlink:href="#info-icon-00"/>
                </mask>
                <use
                  :fill="color"
                  fill-rule="nonzero"
                  xlink:href="#info-icon-00"
                />
                <g
                  :fill="color"
                  :mask="`url(#${maskId})`"
                >
                  <path d="M0 0H24V24H0z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  </div>
</template>

<script>
  export default {
    name: "InfoIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: '#757575'
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      maskId: {
        type: String,
        required: false,
        default: 'info-icon-01'
      },
    }
  }
</script>

<style scoped>

</style>