import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import { defineStore } from 'pinia';

interface NotificationMessage {
  code: string;
  scope: string;
}

interface Notification {
  id?: string;
  message: NotificationMessage;
  type: string;
  payload?: object;
  displayOptions?: DisplayOptions;
};

interface DisplayOptions {
  type: string;
  position?: object;
  transition?: string;
  timeout: number;
};

interface State {
  notifications: Notification[];
  displayOptions: DisplayOptions;
  showNotifications: boolean;
  displayGeneralNotifications: boolean;
}

export const useNotifierStore = defineStore('notifier', {
  state: (): State => ({
    notifications: [],
    displayOptions: {
      type: 'snackbar',
      position: {
        top: '20px',
        left: '50%'
      },
      transition: 'bottom',
      timeout: 5
    },
    showNotifications: true,
    displayGeneralNotifications: true,
  }),

  actions: {
    disableNotifications() {
      this.showNotifications = false;
    },
    enableNotifications() {
      this.showNotifications = true;
    },
    submitNotification (notification: Notification) {
      // For now we only display one notification
      Vue.set(this, 'notifications', []);
      if (this.showNotifications) {
        const notificationWIthId = Object.assign({}, notification, { id: uuid() });
        Vue.set(this.notifications, 0, notificationWIthId);
      }
    },

    substractNotification (notification: Notification) {
      this.notifications = this.notifications.filter(not => {
        return not.id !== notification.id;
      });
    },

    removeAllNotifications () {
      Vue.set(this, 'notifications', []);
    },

    updateDisplayOptions (displayOptions: DisplayOptions) {
      this.displayOptions = displayOptions;
    }
  }
});

