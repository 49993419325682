<template>
  <svg 
    :width="width" 
    :height="height" 
    viewBox="0 0 30 30"
  >
    <defs>
      <circle 
        id="04xjhy62ya" 
        cx="15" 
        cy="15" 
        r="15"
      />
      <path
        id="t96zbsc2lc"
        d="M0 17.813h20.625L10.312 0 0 17.813zM11.25 15H9.375v-1.875h1.875V15zm0-3.75H9.375V7.5h1.875v3.75z"
      />
    </defs>
    <g 
      fill="none" 
      fill-rule="evenodd"
    >
      <g>
        <g>
          <g>
            <g transform="translate(-360 -160) translate(0 40) translate(340 100) translate(20 20)">
              <mask 
                id="wq1687oksb" 
                fill="#fff"
              >
                <use xlink:href="#04xjhy62ya"/>
              </mask>
              <use 
                fill="#C3C3C3" 
                xlink:href="#04xjhy62ya"
              />
              <g 
                :fill="color" 
                mask="url(#wq1687oksb)"
              >
                <path d="M0 0H30V30H0z"/>
              </g>
              <g mask="url(#wq1687oksb)">
                <g>
                  <path
                    d="M0 0L22.5 0 22.5 22.5 0 22.5z"
                    transform="translate(3.75 3.75)"
                  />
                  <g transform="translate(3.75 3.75) translate(.938 1.875)">
                    <mask 
                      id="1v8hblrcgd" 
                      fill="#fff"
                    >
                      <use xlink:href="#t96zbsc2lc"/>
                    </mask>
                    <g 
                      fill="#FFF" 
                      mask="url(#1v8hblrcgd)"
                    >
                      <path
                        d="M0 0H22.5V22.5H0z"
                        transform="translate(-.938 -1.875)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CircleWarningIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: "#D32F2F",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 30;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 30;
      },
    },
  },
};
</script>