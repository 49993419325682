/* eslint-disable no-undef */
/* eslint-disable no-magic-numbers */

/** This code is responsible for managing the number of open tabs in a user's session.
 *  It uses window.onload and window.onbeforeunload to detect when a page is loaded and unloaded.
 */

/**
 * creates listeners for window.onload and window.onbeforeunload events
 * @returns {void}
 */
export const sessionTabsCountHandler = () => {
  window.addEventListener('load', onLoadHandler);
  window.addEventListener('beforeunload', onBeforeUnloadHandler);
};

/**
 * a helper method to check if session tabs count is null or 0. That means that the user had no open tabs before opening a new one.
 * @param {String} sessionTabsCount - number of open tabs in a user's session as a string
 * @returns {boolean} - true if the user had no open tabs before opening a new one, false otherwise
 */
const areSessionTabsEmpty = (sessionTabsCount) => {
  return (!sessionTabsCount || sessionTabsCount === '0');
};

/**
 * When a page is loaded, it checks whether nb_session_tabs_count
 * localStorage property is set and if not, it sets it to 1.
 * Otherwise it increments its value.
 * @returns {void}
 */
const onLoadHandler = () => {
  if (!isLocalStorageAvailable) {
    return
  }

  const sessionTabsCount = getCookie('nb_session_tabs_count');

  /**
   * this condition checks if the user has opened a new tab and if the nb_open_tab sessionStorage property is not set.
   * It won't be set for newly opened tabs.
   * That's how we know that the user had no open tabs before opening a new one.
   * @returns {void}
   */
  if (areSessionTabsEmpty(sessionTabsCount) && !getFromSessionStorage('nb_open_tab')) {
    cleanSessionCookies();
    setInSessionStorage('nb_open_tab', true);

    /**
     * We don't want to reload the page if the user is on the welcome app.
     */
    if (process.env.VUE_APP_NAME === 'welcome-app') {
      return;
    }

    /**
     * If the user is not on the welcome app, but has opened a new tab, it reloads the page.
     * Being not on the welcome app while having opened a new tab means that the user can't be authenticated at this point.
     * Therefore, after cleaning the session data, the page is reloaded to ensure that the user gets back to login page.
     */
    window.location.reload();
  }

  // if the user has opened a new tab, it sets nb_open_tab sessionStorage property to true
  setInSessionStorage('nb_open_tab', true);
  increaseTabsCount(sessionTabsCount);
};

/**
 * When a page is unloaded, it decrements nb_session_tabs_count's value.
 * If the value is 1, it sets it to 0 and calls the cleanSessionCookies function
 * which deletes session data from localstorage.
 * @returns {void}
 */
const onBeforeUnloadHandler = () => {
  if (isLocalStorageAvailable) {
    const sessionTabsCount = getCookie('nb_session_tabs_count');

    decreaseTabsCount(sessionTabsCount);
  }
};

/**
 * deletes session data from localstorage
 * @returns {void}
 */
const cleanSessionCookies = () => {
  deleteCookie('nb_customer_id');
  deleteCookie('nb_person_token');
  deleteCookie('nb_user_id');
  deleteCookie('nb_session_tabs_count');
};

/**
 * decreases the number of open tabs
 * @param {number} sessionTabsCount number of open tabs in a user's session
 * @returns {void}
 */
const decreaseTabsCount = (sessionTabsCount) => {
  if (sessionTabsCount && parseInt(sessionTabsCount) > 1) {
    setCookie('nb_session_tabs_count', parseInt(sessionTabsCount) - 1);
  } else {
    setCookie('nb_session_tabs_count', 0);
  }
};

/**
 * increases the number of open tabs
 * @param {number} sessionTabsCount number of open tabs in a user's session
 * @returns {void}
 */
const increaseTabsCount = (sessionTabsCount) => {
  if (sessionTabsCount) {
    setCookie('nb_session_tabs_count', parseInt(sessionTabsCount) + 1);
  } else {
    setCookie('nb_session_tabs_count', 1);
  }
};
