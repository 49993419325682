import ApiError from './error';

export default class TwoFactorCodeSent extends ApiError {
  code() {
    return 'TwoFactorCodeSent';
  }

  scope() {
    return 'Common';
  }

  display() {
    return false;
  }

  static match(response) {
    return response.data.result &&
           ['AwaitCode', 'SentCode', 'SentCodes'].indexOf(response.data.result.state) >= 0;
  }
}
