
<template>
    <svg :width="width" :height="height" :viewBox="options.viewBox">
        <defs>
            <path id="x6edc91uva" d="M8.59 16.59L13.17 12 8.59 7.41 10 6 16 12 10 18z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0L24 0 24 24 0 24z"/>
            <mask id="rd0xs3uktb" fill="#fff">
                <use xlink:href="#x6edc91uva"/>
            </mask>
            <use fill="#000" fill-rule="nonzero" xlink:href="#x6edc91uva"/>
            <g :fill="color" mask="url(#rd0xs3uktb)">
                <path d="M0 0H24V24H0z"/>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "RightAngleArrowIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: '#AFAFAF'
      },
      width: {
        type: String,
        required: false,
        default: '24px'
      },
      height: {
        type: String,
        required: false,
        default: '24px'
      },
      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 24 24' }
        }
      }
    }
  }
</script>

<style scoped>

</style>