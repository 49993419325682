const buildEnv = process.env.BUILD_ENV || 'local';
const rollbarEnv = process.env.VUE_APP_ROLLBAR_ENV || 'local';

const interpolate = require('interpolate');
const config = require('./config')[buildEnv];

config.buildEnv = buildEnv;
config.rollbarEnv = rollbarEnv;

export const install = (Vue, additionalConfig) => {
  Object.assign(config, additionalConfig || {});

  const newConfig = {};

  for (let i = Object.keys(config).length - 1; i >= 0; i -= 1) {
    const key = Object.keys(config)[i];
    const value = config[key];
    if (value) {
      try {
        const interpolatedValue = interpolate(value, config);
        newConfig[key] = interpolatedValue;
      } catch (e) {
        newConfig[key] = value;
      }
    }
  };

  Vue.prototype.nb_config = newConfig;
}
