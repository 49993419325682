export const COMPANY_INFORMATION_ITEMS = {
  ADDRESS: 'address',
  INDUSTRIES: 'industries',
  REGISTERED_CAPITAL: 'registeredCapital',
  REGISTRATION: 'registration',
  RELATIONS: 'relations',
  SOURCE: 'source',
  SOURCE_DETAILS: 'sourceDetails',
  MODIFIED: 'modified',
  ACCESS: 'access',
  IS_NEW_SEARCH: 'isNewSearch'
};
