<template>
  <div class="info-icon-container">
    <svg
      :width="width"
      :height="height"
      viewBox="0 -1 16 16"
      class="info-icon-non-hover"
    >
        <defs>
            <path id="s3an0e6moa" d="M7.333 11.333h1.334v-4H7.333v4zm.667-10C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm0 12c-2.94 0-5.333-2.393-5.333-5.333C2.667 5.06 5.06 2.667 8 2.667c2.94 0 5.333 2.393 5.333 5.333 0 2.94-2.393 5.333-5.333 5.333zM7.333 6h1.334V4.667H7.333V6z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g transform="translate(-182 -619) translate(20 606) translate(100 10) translate(62 3)">
                            <path d="M0 0L16 0 16 16 0 16z"/>
                            <mask id="bz1dfedapb" fill="#fff">
                                <use xlink:href="#s3an0e6moa"/>
                            </mask>
                            <use
                              :fill="color"
                              fill-rule="nonzero"
                              xlink:href="#s3an0e6moa"/>
                            <g
                              :fill="color"
                              mask="url(#bz1dfedapb)"
                            >
                                <path d="M0 0H16V16H0z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

    <svg
      :width="width"
      :height="height"
      viewBox="0 2 24 24"
      class="info-icon-hover"
    >
      <defs>
        <path
          id="info-icon-00"
          d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 9h-2v6h2v-6zm0-4h-2v2h2V7z"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-519 -293) translate(499 155) translate(10 130) translate(10 8)">
                <path d="M0 0L24 0 24 24 0 24z"/>
                <mask id="info-icon-01" fill="#fff">
                  <use xlink:href="#info-icon-00"/>
                </mask>
                <use
                  :fill="color"
                  fill-rule="nonzero"
                  xlink:href="#info-icon-00"
                />
                <g
                  :fill="color"
                  mask="url(#info-icon-01)"
                >
                  <path d="M0 0H24V24H0z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: "InfoIconHoverable",

    props: {
      color: {
        type: String,
        required: false,
        default: '#757575'
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-icon-container {
    cursor: pointer;

    .info-icon-hover {
      display: none;
    }

    &:hover {
      .info-icon-non-hover {
        display: none;
      }
      .info-icon-hover {
        display: block;
      }
    }
  }
</style>