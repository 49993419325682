<template>
    <div class="icon-container">
        <svg
             version="1.1"
             role="presentation"
             :width=width
             :height=height
             viewBox="-2 -2 22 22"
        >
            <path
                d="M12.5 11h-.79l-.28-.27A6.471 6.471 0 0 0 13 6.5 6.5 6.5 0 1 0 6.5 13c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L17.49 16l-4.99-5zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11z"
                :fill=color
            ></path>
            <path
                    transform="translate(4, 4)"
                    d="M5 3H3v2H2V3H0V2h2V0h1v2h2z"
                    :fill=color
            >
            </path>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "ZoomIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: "#757575"
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    @import '../assets/styles/iconHoverEffectStyle';

</style>