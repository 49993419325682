<template>
  <div class="icon-container">
    <svg
      version="1.1"
      role="presentation"
      :width="width"
      :height="height"
      viewBox="0 0 35 35"
      class="fa-icon"
    >
      <path
        transform="scale(1.5)"
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        :fill="color"
      >
      </path>
    </svg>
  </div>
</template>

<script>
  export default {
    name: "submenuIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: "rgb(117, 117, 117)"
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      }
    }
  }
</script>

<style scoped lang="scss">

  @import '../assets/styles/iconHoverEffectStyle';

</style>