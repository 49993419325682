<template>
  <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
  >
    <defs>
      <path
          id="bnpp8oz35a"
          d="M3.333 10.833h5v-5h3.334L5.833 0 0 5.833h3.333v5zM0 12.5h11.667v1.667H0V12.5z"
      />
    </defs>
    <g
        fill="none"
        fill-rule="evenodd"
    >
      <g>
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0L20 0 20 20 0 20z" transform="translate(-510 -351) translate(0 40) translate(340 100) translate(160 201) translate(10 10)"/>
                <g transform="translate(-510 -351) translate(0 40) translate(340 100) translate(160 201) translate(10 10) translate(4.167 3.333)">
                  <mask id="zwjdsayj9b" fill="#fff">
                    <use xlink:href="#bnpp8oz35a"/>
                  </mask>
                  <use fill="#000" fill-rule="nonzero" xlink:href="#bnpp8oz35a"/>
                  <g :fill="color" mask="url(#zwjdsayj9b)">
                    <path d="M0 0H20V20H0z" transform="translate(-4.167 -3.333)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "UploadIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: '#757575'
    },
    width: {
      type: Number,
      required: false,
      default: 24
    },
    height: {
      type: Number,
      required: false,
      default: 24
    }
  }
}
</script>

<style scoped>

</style>