<template>
  <div class="national-id-number-icon-container">
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
          <path d="M0 0h24v24H0z"/>
          <path 
            d="M10 15c2.67 0 8 1.34 8 4v3H2v-3c0-2.66 5.33-4 8-4zm0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V19c0-.64-3.13-2.1-6.1-2.1zM10 6c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 1.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2zM17.2 2v1.6h1.6V2h1.6v1.6H22v1.6h-1.6v1.6H22v1.6h-1.6V10h-1.6V8.4h-1.6V10h-1.6V8.4H14V6.8h1.6V5.2H14V3.6h1.6V2h1.6zm1.6 3.2h-1.6v1.6h1.6V5.2z" 
            :fill="color"
          />
      </g>
    </svg>
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "NationalIdentificationNumberIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },
    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },
  }
}
</script>

<style scoped lang="scss">
.national-id-number-icon-container {
  display: flex;
  justify-content: center;
}
</style>