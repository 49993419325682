//
// Proxies any method calls to the API transparently.
//
// You can call any method on the API parsing in an object as the only
// parameter.
//
// Example:
//
//   // main.js
//   import { install as Api } from '@packages/api';
//   Vue.use(Api);
//
//   // Inside a Vue instance
//   this.api.login({ email: 'hello@gmail.com', password: 'password' });
//
import ApiRequest from '@packages/api-request';
import CustomApiRequest from './modules/customApiRequest';
import AxiosRequest from './modules/axiosRequest';
import { NotifierMixin } from '@packages/notifier';
import ApiCallMixin from './mixins/apiCallMixin';
import { sessionTabsCountHandler } from './utils/sessionHelpers';
import { useNotifierStore, useApiCallsStore } from '@packages/stores';

// Utilities
import { tooManyRequestsConstants } from '@packages/constants';
const {
  TIMEOUT_DELAY,
  TIMEOUT_DELAY_IN_SECONDS,
  TOO_MANY_REQUESTS_ERROR_CODES
} = tooManyRequestsConstants;

import {
  calculateReplenishmentTime
} from './utils/tooManyRequestsErrorHelpers';


class NbApiMap {
  constructor(options) {
    this.options = options || {};
    this.cache = {};
  }

  async request(method, data, methodOptions) {
    if (!data) data = {};

    // If we have a cached version of this endpoint
    const cacheKey = `${method}-${JSON.stringify(data)}`;

    if (this.cache[cacheKey]) {
      const response = this.cache[cacheKey];

      return response;
    }

    // If no auth object is set by call params, construct it according to: defaults -> method options -> passed parameters
    if (!data.auth) {
      const authObj = JSON.parse(JSON.stringify(apiInstance.defaultAuthObj));

      if (methodOptions.authType) {
        authObj.type = methodOptions.authType;
      }

      if (data.authType) {
        authObj.type = data.authType;
      }

      // If there is a customerId set in the method call, pass it to the request
      if (authObj.type === 'admin' && typeof data.customerId !== 'undefined') {
        authObj.params.customerId = data.customerId;
      }

      if (authObj.type !== 'admin' || typeof authObj.params.customerId === 'undefined') {
        delete authObj.params.customerId;
      }

      data.auth = authObj;
    }

    // Construct the API request object
    const requestObj = new ApiRequest(this.options.url, method, data);

    // Call the API
    // Regardless of the API answer, return that answer
    try {
      // window.console.log(`Calling API ${this.options.url}#${method}`);
      const apiCallsStore = useApiCallsStore();
      apiCallsStore.setMostRecentAuthObj(data.auth);
      const response = await requestObj.request();

      // Handle dependent actions' caches
      this.bustCache(methodOptions);

      // Cache the response
      if (methodOptions.cache) {
        this.cache[cacheKey] = response;
      }

      return response;
    } catch (error) {
      const errorCode = typeof error?.code === 'function' ? error?.code() : error.code
      if (TOO_MANY_REQUESTS_ERROR_CODES.includes(errorCode)) {
        const replenishmentTime = calculateReplenishmentTime(error.response.data?.error?.data?.details?.tokenBucket || 0);
        if (replenishmentTime <= TIMEOUT_DELAY_IN_SECONDS) {
          NotifierMixin.methods.notify({
            type: 'too-many-requests',
          });
          await new Promise((resolve) => setTimeout(resolve, TIMEOUT_DELAY));
          const notifierStore = useNotifierStore();
          notifierStore.removeAllNotifications();
          // Retry the API call after waiting
          const apiCallToRetry = this.request.bind(this);

          return await apiCallToRetry(method, data, methodOptions);
        }
      }

      // The checkSession method shouldn't raise an error when failing.
      // It should just return false.
      if (method === 'checkSession') return false;
      window.apiError = error;
      if (this.options.onError) this.options.onError(error);
      throw error;
    }
  }

  bustCache(options) {
    if (options.bust) {
      for (let i = options.bust.length - 1; i >= 0; i -= 1) {
        const bustKey = options.bust[i];

        for (let j = Object.keys(this.cache).length - 1; j >= 0; j -= 1) {
          const cacheKey = Object.keys(this.cache)[j];
          if (cacheKey.indexOf(bustKey) >= 0) {
            this.cache[cacheKey] = null;
          }
        };
      };
    }
  }

  clearCache() {
    this.cache = {};
  }

  /****** Session management ******/
  getActiveSession() {
    return apiInstance.defaultAuthObj.params;
  }

  removeSessionFromCookies() {
    if (isLocalStorageAvailable) {
      deleteCookie('nb_user_id');
      deleteCookie('nb_person_token');
      deleteCookie('nb_customer_id');
      deleteCookie('nb-timestamp');
    }
  }

  removeActiveSession() {
    if (isLocalStorageAvailable) {
      deleteCookie('nb_user_id');
      deleteCookie('nb_person_token');
      deleteCookie('nb_customer_id');
      deleteCookie('nb-timestamp');
    }

    apiInstance.defaultAuthObj.params.customerId = null;
    apiInstance.defaultAuthObj.params.personToken = null;
    apiInstance.defaultAuthObj.params.userId = null;
  }

  setLocalActiveSession(sessionParam) {
    const session = JSON.parse(JSON.stringify(sessionParam));

    if (!session ||
        !session.personToken ||
        !session.userId) {
      return Promise.reject('InvalidSession');
    }

    if (session.customerId) {
      apiInstance.defaultAuthObj.params.customerId = sessionParam.customerId;
    }
    apiInstance.defaultAuthObj.params.personToken = sessionParam.personToken;
    apiInstance.defaultAuthObj.params.userId = sessionParam.userId;

    return Promise.resolve();
  }

  setActiveSession(sessionParam) {
    const session = JSON.parse(JSON.stringify(sessionParam));

    if (!session ||
        !session.personToken ||
        !session.userId) {
      return Promise.reject('InvalidSession');
    }

    if (session.customerId) {
      this.setCustomerId(session.customerId);
    }

    this.setPersonToken(session.personToken);
    this.setUserId(session.userId);

    return Promise.resolve();
  }

  setUserId(userId) {
    if (isLocalStorageAvailable) {
      setCookie('nb_user_id', userId);
    }
    apiInstance.defaultAuthObj.params.userId = userId;
  }

  setPersonToken(personToken) {
    if (isLocalStorageAvailable) {
      setCookie('nb_person_token', personToken);
    }
    apiInstance.defaultAuthObj.params.personToken = personToken;
  }

  setCustomerId(customerId) {
    if (isLocalStorageAvailable) {
      setCookie('nb_customer_id', customerId);
    }
    apiInstance.defaultAuthObj.params.customerId = customerId;
  }

  /******  End of session management ******/
};

NbApiMap.prototype.sessionTabsCountHandler = sessionTabsCountHandler;

let apiInstance = {};

export const install = (Vue, options) => {
  if (!options) options = {};

  if (!options.defaultAuthType) {
    return Promise.reject('Missing defaultAuthType param. Property must be set by the parent application');
  }

  apiInstance = new NbApiMap(options);

  // Set the default auth object
  apiInstance.defaultAuthObj = {
    type: options.defaultAuthType,
    params: isLocalStorageAvailable ? {
      userId: getCookie('nb_user_id'),
      personToken: getCookie('nb_person_token'),
      customerId: getCookie('nb_customer_id')
    } : {}
  }

  const methods = require('./methods.json');

  for (let i = Object.keys(methods).length - 1; i >= 0; i -= 1) {
    const method = Object.keys(methods)[i];
    const methodOptions = methods[method];

    apiInstance[method] = async (data) => {
      let response = await apiInstance.request(method, data, methodOptions);

      // If we have a factory class, use that to evaluate the response before
      // returning it.
      if (options.factory) response = options.factory(response);

      // If we have an afterEach callback, execute it
      if (options.afterEach) options.afterEach(response, method);

      return Promise.resolve(response);
    }
  };

  Vue.mixin({
    methods: {
      $apiCall: ApiCallMixin.methods.call,
      $customApiCall: ApiCallMixin.methods.customCall,
    }
  });
  apiInstance.customRequest = CustomApiRequest.request;
  apiInstance.getAuthObject = CustomApiRequest.getAuthObject;
  Vue.prototype.$axiosRequest = new AxiosRequest(options?.mockResponses || {});
  Vue.prototype.api = apiInstance;
  Vue.api = apiInstance;
  window.api = apiInstance;
};

export default apiInstance;

