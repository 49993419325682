import { lib } from '@packages/helpers';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NotifierMixin } from '@packages/notifier';
import { ErrorObject } from '@models/errorObject';

import Vue from 'vue';
import { defineStore } from 'pinia';

interface State {
  // isTooManyRequestsError: boolean;
  mostRecentAuthObj: object;
}

interface Process {
  caseManagement: boolean;
}

export const useApiCallsStore = defineStore('apiCalls', {
  state: (): State => ({
    // isTooManyRequestsError: false,
    mostRecentAuthObj: {},
  }),

  actions: {
    async apiCall(params: { apiName: string; apiParams: object; errorHandler: (arg0: unknown) => void }) {
      if (!params.apiName) {
        return Promise.reject('apiName is missing');
      }
      try {
        const response = params.apiParams
          ? await Vue.prototype.api[params.apiName](params.apiParams)
          : await Vue.prototype.api[params.apiName]();

        return Promise.resolve(response);
      } catch (error: any) {
        if (typeof params.errorHandler === 'function') {
          const enhancedError = error;
          enhancedError.apiMethod = params?.apiName;
          params.errorHandler(enhancedError);

          return Promise.reject(enhancedError);
        }
        const errorObj = lib.createErrorObj(error) as ErrorObject;
        errorObj.apiMethod = params?.apiName;
        NotifierMixin.methods.notify(errorObj);

        return Promise.reject(errorObj);
      }
    },

    async getCustomerProcesses(params: { apiParams: any; errorHandler: (arg0: unknown) => void }) {
      try {
        const response = params.apiParams
          ? await Vue.prototype.api.getCustomer(params.apiParams)
          : await Vue.prototype.api.getCustomer();

        // 2.0 compatibility: filter out case management processes
        return Promise.resolve(response.processes.filter(({ caseManagement }: Process) => !caseManagement));
      } catch (error: any) {
        if (typeof params.errorHandler === 'function') {
          params.errorHandler(error);

          return Promise.reject(error);
        }
        const errorObj = lib.createErrorObj(error) as ErrorObject;
        NotifierMixin.methods.notify(errorObj);

        return Promise.reject(errorObj);
      }
    },

    setMostRecentAuthObj(params: object) {
      this.mostRecentAuthObj = params;
    },
  },
});
