<template>
    <svg :width="width" :height="height" :viewBox="options.viewBox">
    <defs>
        <path id="jcawpjoq6a" d="M21.6 1H2.4c-.708 0-1.388.855-1.388 2L1 21c0 1.14.687 2 1.4 2h19.2c.713 0 1.4-.86 1.4-2V3c0-1.14-.687-2-1.4-2zm0-1C22.92 0 24 1.35 24 3v18c0 1.65-1.08 3-2.4 3H2.4C1.08 24 0 22.65 0 21L.012 3c0-1.65 1.068-3 2.388-3h19.2zm-3.84 12.706H3.36v1.412h14.4v-1.412zm0-4.235H3.36v1.411h14.4V8.471zM12 4.235H3.36v1.412H12V4.235z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g transform="translate(-430 -368) translate(0 100) translate(410 76) translate(10 100) translate(0 79) translate(10 13)">
                                <path d="M0 0L24 0 24 24 0 24z"/>
                                <mask id="h180q3miab" fill="#fff">
                                    <use xlink:href="#jcawpjoq6a"/>
                                </mask>
                                <use :fill="COLORS.PRIMARY" fill-rule="nonzero" xlink:href="#jcawpjoq6a"/>
                                <g :fill="color" mask="url(#h180q3miab)">
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

</template>

<script>
import { COLORS } from '@packages/web-resources';

  export default {
    name: "DocumentIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: COLORS.PRIMARY
      },

      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },

      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },

      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 50 50' }
        }
      }
    },

    created () {
      this.COLORS = COLORS;
    },
  }
</script>

<style scoped>

</style>