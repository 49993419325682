<template>
  <svg 
    :width="width" 
    :height="height" 
    viewBox="0 0 48 48" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M.627.495h48v48h-48z"/>
      <path 
        d="M39.327 20.575c-1.36-6.9-7.42-12.08-14.7-12.08-5.78 0-10.8 3.28-13.3 8.08-6.02.64-10.7 5.74-10.7 11.92 0 6.62 5.38 12 12 12h26c5.52 0 10-4.48 10-10 0-5.28-4.1-9.56-9.3-9.92zm-.7 15.92h-26c-4.42 0-8-3.58-8-8 0-4.1 3.06-7.52 7.12-7.94l2.14-.22 1-1.9c1.9-3.66 5.62-5.94 9.74-5.94 5.24 0 9.76 3.72 10.78 8.86l.6 3 3.06.22c3.12.2 5.56 2.82 5.56 5.92 0 3.3-2.7 6-6 6zm-22-10h5.1v6h5.8v-6h5.1l-8-8-8 8z" 
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "CloudIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: "#AFAFAF"
      },

      width: {
        type: Number,
        required: false,
        default: () => 52

      },

      height: {
        type: Number,
        required: false,
        default: () => 48
      }
    }
  }
</script>

<style scoped>

</style>