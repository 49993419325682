<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="bh80mibv0a"
        d="M7.41 8.59L12 13.17 16.59 8.59 18 10 12 16 6 10z"
      />
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <path d="M0 0L24 0 24 24 0 24z"/>
      <mask
        id="wxyz823v2b"
        fill="#fff"
      >
        <use xlink:href="#bh80mibv0a"/>
      </mask>
      <use
        fill="#000"
        fill-rule="nonzero"
        xlink:href="#bh80mibv0a"
      />
      <g
        :fill="color"
        mask="url(#wxyz823v2b)"
      >
        <path d="M0 0H24V24H0z"/>
      </g>
    </g>
  </svg>

</template>

<script>
  export default {
    name: "ArrowDown",

    props: {
      color: {
        type: String,
        required: false,
        default: "#757575"
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      }
    }

  }
</script>

<style scoped>

</style>