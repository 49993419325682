import VueRollbar from 'vue-rollbar';
import { baseConfig, envSpecificConfigs } from './config';
const buildEnv = process.env.VUE_APP_ROLLBAR_ENV || process.env.VUE_APP_BUILD_ENV

if (!buildEnv) {
  throw new Error('VUE_APP_ROLLBAR_ENV or VUE_APP_BUILD_ENV must be set');
}

const envSpecificConfig = envSpecificConfigs[buildEnv];
const config = Object.assign({}, baseConfig, envSpecificConfig);

export { config as rollbarConfig }

export default (Vue: any) => {
  try {
  Vue.use(VueRollbar, config);
  } catch (e) {
    window.console.error('Unable to setup rollbar!', e);
  }
}
