<template>
  <div class="company-number-icon-container">
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M15 11v2h2v-2h2v2h2v2h-2v2h2v2h-2v2h-2v-2h-2v2h-2v-2h-2v-2h2v-2h-2v-2h2v-2h2zm-3-8v4h10v4h-2V9h-9.99V4.996H4V19h6v2H2V3h10zm5 12h-2v2h2v-2z"
          :fill="color" />
      </g>
    </svg>
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "CompanyRegisterationNumberIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },
    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },
  }
}
</script>

<style scoped lang="scss">
.company-number-icon-container {
  display: flex;
  justify-content: center;
}
</style>