<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="84jc5l7dxa"
        d="M7.41 15.41L12 10.83 16.59 15.41 18 14 12 8 6 14z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g
              transform="translate(-1246 -169) translate(0 156) translate(1215.765 13) translate(30.235)"
            >
              <path d="M0 0L24 0 24 24 0 24z" />
              <mask id="673xuq0q4b" fill="#fff">
                <use xlink:href="#84jc5l7dxa" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#84jc5l7dxa" />
              <g :fill="color" mask="url(#673xuq0q4b)">
                <path d="M0 0H24V24H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArrowUp",

  props: {
    color: {
      type: String,
      required: false,
      default: "#757575",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },
};
</script>

<style scoped>
</style>