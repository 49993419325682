<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 60 60">
      <defs>
          <path id="5vq8wf4zca" d="M46.844 26.342C45.285 18.435 38.342 12.5 30 12.5c-6.623 0-12.375 3.758-15.24 9.258-6.897.734-12.26 6.577-12.26 13.659 0 7.585 6.165 13.75 13.75 13.75h29.792c6.325 0 11.458-5.134 11.458-11.459 0-6.05-4.698-10.954-10.656-11.366zm-12.26 6.783v9.167h-9.167v-9.167h-6.875L30 21.667l11.458 11.458h-6.875z"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <g>
              <g>
                  <g transform="translate(-610 -257) translate(265 215) translate(345 42)">
                      <path d="M0 0L60 0 60 60 0 60z"/>
                      <mask id="jv0ttzl6jb" fill="#fff">
                          <use xlink:href="#5vq8wf4zca"/>
                      </mask>
                      <use :fill="color" fill-rule="nonzero" xlink:href="#5vq8wf4zca"/>
                      <g :fill="color" mask="url(#jv0ttzl6jb)">
                          <path d="M0 0H60V60H0z"/>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "UploadCloudIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: '#757575'
    },
    width: {
      type: Number,
      required: false,
      default: 24
    },
    height: {
      type: Number,
      required: false,
      default: 24
    }
  }
}
</script>

<style scoped>

</style>