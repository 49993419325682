<template>
    <svg :width="width" :height="height" viewBox="0 0 40 40">
        <defs>
            <path id="1bz4b5fjka" d="M21.667 5c-8.284 0-15 6.717-15 15h-5l6.483 6.483.117.234L15 20h-5c0-6.45 5.217-11.667 11.667-11.667S33.333 13.55 33.333 20s-5.216 11.667-11.666 11.667c-3.217 0-6.134-1.317-8.234-3.434L11.067 30.6c2.716 2.717 6.45 4.4 10.6 4.4 8.283 0 15-6.717 15-15 0-8.283-6.717-15-15-15zM20 13.333v8.334l7.133 4.233 1.2-2.017-5.833-3.466v-7.084H20z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M0 0L40 0 40 40 0 40z" transform="translate(-621 -257) translate(390 136) translate(20 20) translate(211 101)"/>
                            <g transform="translate(-621 -257) translate(390 136) translate(20 20) translate(211 101)">
                                <path d="M0 0L40 0 40 40 0 40z"/>
                                <mask id="ibz1862prb" fill="#fff">
                                    <use xlink:href="#1bz4b5fjka"/>
                                </mask>
                                <use fill="#86BEE0" fill-rule="nonzero" xlink:href="#1bz4b5fjka"/>
                                <g :fill="color" mask="url(#ibz1862prb)">
                                    <path d="M0 0H40V40H0z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "DataExpiredIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: "#afafaf"
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 40;
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 40;
                }
            }
        }
    }
</script>
