<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20">
        <defs>
            <path id="49rs8ke9la" d="M8.333 16.667C3.731 16.667 0 12.936 0 8.333 0 3.731 3.731 0 8.333 0c4.603 0 8.334 3.731 8.334 8.333 0 4.603-3.731 8.334-8.334 8.334zm.323-12.32V1.049c.345.015.684.054 1.014.115.623.876 1.13 1.753 1.52 2.633-.846.326-1.69.51-2.534.55zm0 .539c.907-.044 1.816-.253 2.728-.63.485 1.22.748 2.441.788 3.667H8.656V4.886zm4.445-2.07c-.433.277-.866.517-1.299.72-.337-.717-.752-1.433-1.243-2.148.946.302 1.808.793 2.542 1.428zm.34.313c1.257 1.234 2.069 2.92 2.173 4.794h-2.709C12.862 6.605 12.56 5.29 12 3.977c.48-.237.96-.52 1.44-.848zM7.923 4.346c-.846-.043-1.688-.23-2.53-.562.388-.868.888-1.735 1.501-2.6.335-.067.679-.112 1.029-.131v3.293zm0 .539v3.038H4.407c.04-1.23.305-2.457.793-3.68.906.381 1.814.595 2.723.642zm-4.37-2.058c.708-.615 1.534-1.095 2.441-1.402-.476.697-.88 1.395-1.21 2.094-.41-.196-.82-.427-1.23-.692zm-.336.311c.456.316.912.59 1.37.82-.566 1.32-.87 2.641-.914 3.965h-2.62c.104-1.87.912-3.552 2.164-4.785zm5.44 9.146c.83.04 1.662.219 2.496.536-.402.898-.923 1.797-1.562 2.697-.305.053-.618.087-.935.101v-3.334zm0-.538v-3.09h3.512c-.049 1.233-.322 2.468-.82 3.704-.9-.367-1.798-.571-2.693-.614zm4.467 2.084c-.761.665-1.662 1.173-2.653 1.477.512-.744.943-1.487 1.294-2.228.453.21.906.46 1.36.751zm.338-.313c-.5-.343-.999-.636-1.497-.88.573-1.331.885-2.658.938-3.98h2.715c-.083 1.898-.892 3.609-2.156 4.86zm-5.54-1.232v3.329c-.321-.018-.638-.057-.947-.115-.63-.89-1.145-1.779-1.544-2.667.829-.322 1.659-.504 2.492-.547zm0-.538c-.896.046-1.792.255-2.686.627-.502-1.241-.778-2.48-.827-3.718h3.514v3.09zM3.53 13.82c.43-.28.86-.521 1.291-.725.344.724.765 1.45 1.263 2.176-.952-.308-1.818-.807-2.554-1.451zm-.334-.313c-1.26-1.25-2.065-2.957-2.147-4.85h2.627c.053 1.328.368 2.661.946 3.998-.476.238-.951.522-1.426.852z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0L20 0 20 20 0 20z"/>
            <g transform="translate(1.667 1.667)">
                <mask id="s1uuz15s6b" fill="#fff">
                    <use xlink:href="#49rs8ke9la"/>
                </mask>
                <use :fill="COLORS.PRIMARY" xlink:href="#49rs8ke9la"/>
                <g :fill="color" mask="url(#s1uuz15s6b)">
                    <path d="M0 0H20V20H0z" transform="translate(-2 -2)"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';

    export default {
        name: "OutlineWorldIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: '#fff'
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 20
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 20
                }
            }
        },

        created () {
            this.COLORS = COLORS;
        }
    }
</script>

<style lang="scss" scoped>

</style>