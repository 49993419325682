<template>
    <div class="icon-container">
        <svg
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             :width="width"
             :height="height"
             viewBox="0 0 24 24"
        >
            <defs>
                <path
                      id="bin__a"
                      d="M5.111 19.778C5.111 21 6.111 22 7.333 22h8.89c1.221 0 2.221-1 2.221-2.222V6.444H5.111v13.334zM19.556 3.11h-3.89L14.557 2H9L7.889 3.111H4v2.222h15.556V3.111z"
                />
            </defs>
            <g fill="none" fill-rule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z"/>
                <mask id="bin__b" fill="#fff">
                    <use xlink:href="#bin__a"/>
                </mask>
                <use
                     :fill="color"
                     fill-rule="nonzero"
                     xlink:href="#bin__a"
                />
                <g :fill=color mask="url(#bin__b)">
                    <path d="M0 0H24V24H0z"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "BinIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: "#757575"
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    @import '../assets/styles/iconHoverEffectStyle';

</style>
