<template>
    <svg :width="width" :height="height" :viewBox="options.viewBox">
        <defs>
            <path id="prefix__a" d="M25 2.083H2.083v33.334H25v-6.25h2.083V37.5H0V0h27.083v8.333H25v-6.25zM36.33 17.39l-4.943-5.038 1.812-1.935 8.468 8.333-8.457 8.333-1.834-1.916 5.091-5.162h-19.8V17.39H36.33z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0L50 0 50 50 0 50z"/>
            <g transform="translate(4.167 6.25)">
                <mask id="prefix__b" fill="#fff">
                    <use xlink:href="#prefix__a"/>
                </mask>
                <use fill="#979797" fill-rule="nonzero" xlink:href="#prefix__a"/>
                <g :fill="color" mask="url(#prefix__b)">
                    <path d="M0 0H50V50H0z" transform="translate(-4 -6)"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "LoggedOut",

    props: {
      color: {
        type: String,
        required: false
      },
      width: {
        type: String,
        required: false
      },
      height: {
        type: String,
        required: false
      },
      options: {
        type: Object,
        required: false,
        default: () => {
            return { viewBox: '0 0 46 46' }
        }
      }
    }
}
</script>

<style>

</style>