import posthogJs, { PostHog } from 'posthog-js';
import Rollbar from 'rollbar';
import { rollbarConfig } from '@packages/rollbar';
import { Customer, Group, User, GroupProperties } from '../types';
const rollbar = new Rollbar(rollbarConfig);
let posthogInstance: void | PostHog = undefined;

const initAndGetPosthogInstance = () => {
  const posthogKey = process.env['VUE_APP_POSTHOG_KEY'];

  if (!posthogKey) {
    console.warn('No posthog token found: VUE_APP_POSTHOG_KEY');

    return;
  }

  posthogInstance = posthogJs.init(posthogKey, {
    // eslint-disable-next-line camelcase -- Posthog expects snake_case
    api_host: 'https://eu.posthog.com',
    // eslint-disable-next-line camelcase -- Posthog expects snake_case
    opt_in_site_apps: true,
  });

  return posthogInstance;
};

/**
 * Gets the posthog instance and initializes it
 * if it's not already initialized
 * @returns {PostHog} posthogInstance
 */
const getPosthogInstance = () => {
  if (posthogInstance) {
    return posthogInstance;
  }

  return initAndGetPosthogInstance();
};

const handleError = (error: unknown) => {
  if (!error || !(error instanceof Error)) return;
  rollbar.error(error);
};

/**
 * Identifies the user and customer within the posthog instance
 * @param {Object} user - The user object for identifying
 * @param {Object} customer - The customer object for grouping
 * @returns {void}
 */
const identify = ({ user, customer }: { user: User; customer?: Customer }) => {
  try {
    if (!posthogInstance) {
      throw new Error('Posthog instance not initialized');
    }

    posthogInstance.identify(user.userId, user);

    if (customer) {
      posthogInstance.group(Group.Customer, customer.customerId, customer);
    }
  } catch (error: unknown) {
    handleError(error);
  }
};

const group = <T extends keyof GroupProperties>(groupName: T, groupId: string, properties: GroupProperties[T]) => {
  try {
    if (!posthogInstance) {
      throw new Error('Posthog instance not initialized');
    }

    posthogInstance.group(groupName, groupId, properties);
  } catch (error: unknown) {
    handleError(error);
  }
};

const resetIdentification = () => {
  try {
    if (!posthogInstance) {
      throw new Error('Posthog instance not initialized');
    }

    posthogInstance.reset();
  } catch (error: unknown) {
    handleError(error);
  }
};

const captureEvent = (name: string) => {
  try {
    if (!posthogInstance) {
      throw new Error('Posthog instance not initialized');
    }

    posthogInstance.capture(name);
  } catch (error: unknown) {
    handleError(error);
  }
};

export { getPosthogInstance, identify, group, resetIdentification, captureEvent };

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This can be either Vue 2 or 3
  install(Vue: any) {
    if (!posthogInstance) {
      initAndGetPosthogInstance();
    }

    if (Vue.version.startsWith('3')) {
      Vue.provide('posthog', posthogInstance);
    } else {
      Vue.prototype.$posthog = posthogInstance;
    }
  },
};
