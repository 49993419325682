<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="rado33eyya"
        d="M5.833 10h3.334V8.333H5.833V10zM0 0v1.667h15V0H0zm2.5 5.833h10V4.167h-10v1.666z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L20 0 20 20 0 20z"
              transform="translate(-757 -126) translate(747 116) translate(10 10)"
            />
            <g transform="translate(-757 -126) translate(747 116) translate(10 10) translate(2.5 5)">
              <mask id="diez0vz7tb" fill="#fff">
                <use xlink:href="#rado33eyya"/>
              </mask>
              <use
                fill="#000"
                fill-rule="nonzero"
                xlink:href="#rado33eyya"
              />
              <g :fill="color" mask="url(#diez0vz7tb)">
                <path d="M0 0H20V20H0z" transform="translate(-2.5 -5)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "FilterIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: "#757575"
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
          return 24
        }
      }
    }
  }
</script>

<style scoped>

</style>