<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
  >
    <title>Icon/Logo/MitID</title>
    <g id="Icon/Logo/MitID" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M13.7142205,5.21469109 L16.006008,5.21469109 C21.6221992,5.21469109 24,8.26645309 24,12.0843497 C24,15.9020182 21.6221992,18.9540083 16.006008,18.9540083 L13.7142205,18.9540083 L13.7142205,5.21446294 L13.7142205,5.21469109 Z M12.0001141,18.9540083 L-3.55271368e-15,18.9540083 C0.342228095,14.7172245 2.87927904,12.9428859 5.99994296,12.9428859 C9.12060688,12.9428859 11.6581141,14.7172245 12.0001141,18.9537802 L12.0001141,18.9540083 Z M9.32160885,8.32759784 C9.32160885,10.1651346 7.83428555,11.6549675 5.99994296,11.6549675 C4.16560037,11.6549675 2.67850522,10.1651346 2.67850522,8.32759784 C2.67850522,6.48983297 4.16560037,5 5.99994296,5 C7.83428555,5 9.3213807,6.48983297 9.3213807,8.32759784 L9.32160885,8.32759784 Z" id="Combined-Shape" fill="#0060E6"></path>
    </g>
</svg>
</template>

<script>
export default {
  name: "MitIDAccount",

  props: {
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },
};
</script>

<style scoped>
</style>