<template>
    <!--SVG icon-->
    <svg 
      class="logo"
      :width="width" 
      :height="height"
      :viewBox="options.viewBox"
    >
      <defs>
          <path id="path-tab-icon" d="M16 16H2V2h7V0H2C.89 0 0 .9 0 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V9h-2v7zM11 0v2h3.59l-9.83 9.83 1.41 1.41L16 3.41V7h2V0h-7z"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <path d="M0 0L24 0 24 24 0 24z"/>
          <g transform="translate(3 3)">
              <mask id="mask-tab-icon" fill="#fff">
                  <use xlink:href="#path-tab-icon"/>
              </mask>
              <use
                :fill="color"
                fill-rule="nonzero"
                xlink:href="#path-tab-icon"
              />
              <g mask="url(#mask-tab-icon)">
                  <path d="M0 0H24V24H0z" transform="translate(-3 -3)"/>
              </g>
          </g>
      </g>
    </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';

  export default {
    name: "OpenTabIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: COLORS.PRIMARY
      },
      width: {
        type: Number,
        required: false,
        default: ()=> {
            return 24
        }
      },
      height: {
        type: Number,
        required: false,
        default: ()=> {
            return 24
        }
      },
      options: {
        type: Object,
        required: false,
        default: () => {
            return { viewBox: '0 0 24 24' }
        }
      }
    }
  }
</script>

<style scoped>

</style>