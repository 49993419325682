<template>
  <div class="icon-container">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="15" cy="15" r="15" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="b" fill="#fff">
          <use xlink:href="#a" />
        </mask>
        <use fill="#C3C3C3" xlink:href="#a" />
        <g mask="url(#b)" :fill="color">
          <path d="M0 0h30v30H0z" />
        </g>
        <g mask="url(#b)">
          <path d="M3.75 3.75h22.5v22.5H3.75z" />
          <path
            d="M8.542 22.292a2.09 2.09 0 0 0 2.083 2.083h8.333a2.09 2.09 0 0 0 2.084-2.083v-12.5h-12.5v12.5zM22.083 6.667h-3.645l-1.042-1.042h-5.209l-1.041 1.042H7.5V8.75h14.583V6.667z"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CircleBinIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: "#D32F2F",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/styles/iconHoverEffectStyle";
</style>
