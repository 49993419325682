<template>
    <svg :width="width" :height="height" :viewBox="options.viewBox">
        <defs>
            <circle id="prefix__a" cx="25" cy="25" r="25"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <use :fill="color" fill-opacity=".2" xlink:href="#prefix__a"/>
            <use fill="#E5E5E5" xlink:href="#prefix__a"/>
            <path :fill="COLORS.PRIMARY" d="M14.785 7.046c.465-.594 1.031-.879 1.698-.854H33.46c.655.065 1.213.414 1.674 1.045.461.632.707 1.337.738 2.117V40.71c-.02.717-.265 1.36-.738 1.927-.473.568-1.03.892-1.674.97h-17.06c-.585 0-1.124-.257-1.616-.771-.493-.515-.739-1.244-.737-2.187V9.298c.026-.908.271-1.658.737-2.252z"/>
            <circle cx="24.96" cy="38.929" r="1.559" fill="#FFF"/>
            <path fill="#FFF" d="M15.714 10.952H34.421V35.895H15.714z"/>
            <path :fill="color" fill-rule="nonzero" d="M25.338 22.302c-.426-1.202-1.58-2.064-2.935-2.064-1.72 0-3.117 1.388-3.117 3.095 0 1.708 1.397 3.096 3.117 3.096 1.355 0 2.509-.862 2.935-2.064h2.26v2.064h2.077v-2.064h1.04v-2.063h-5.377zM22.5 24.286c-.59 0-1.071-.482-1.071-1.072 0-.589.482-1.071 1.071-1.071.59 0 1.071.482 1.071 1.071 0 .59-.482 1.072-1.071 1.072z"/>
        </g>
    </svg>

</template>

<script>
  import { COLORS } from '@packages/web-resources';
  
  export default {
    name: "AppVerificationIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: COLORS.PRIMARY
      },
      width: {
        type: String,
        required: false
      },
      height: {
        type: String,
        required: false
      },
      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 50 50' }
        }
      }
    },

    created () {
      this.COLORS = COLORS;
    }
  }
</script>

<style scoped>

</style>