<template>
    <div class="icon-container">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                :width="width"
                :height="height"
                viewBox="0 0 24 24"
        >
            <defs>
                <path
                        id="d5i9cyny4a"
                        d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12z"
                />
            </defs>
            <g
                    fill="none"
                    fill-rule="evenodd"
            >
                <mask
                        id="p1cylyennb"
                        :fill="color"
                >
                    <use xlink:href="#d5i9cyny4a"/>
                </mask>
                <use :fill="color" xlink:href="#d5i9cyny4a"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "CrossIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: "#757575"
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../assets/styles/iconHoverEffectStyle';
</style>