<template>
  <!--SVG icon-->
  <svg 
    :width="width" 
    :height="height" 
    :viewBox="options.viewBox"
  >
    <defs>
      <path id="noiksnlboa" d="M8 0l1.42 1.42L3.832 7H16v2H3.83l5.59 5.59L8 16 0 8l8-8z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0L24 0 24 24 0 24z" />
      <g transform="translate(4 4)">
        <mask id="afkp4ikz3b" :fill="color">
          <use xlink:href="#noiksnlboa" />
        </mask>
        <use :fill="color" fill-rule="nonzero" xlink:href="#noiksnlboa" />
        <g :fill="color" mask="url(#afkp4ikz3b)">
          <path d="M0 0H24V24H0z" transform="translate(-4 -4)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LeftArrowIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: "#757575",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return { viewBox: "0 0 24 24" };
      },
    },
  },
};
</script>

<style scoped>
</style>