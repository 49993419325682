<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
  >
    <defs>
      <path id="jzznzg3wta" d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <path
            d="M0 0L24 0 24 24 0 24z"
            transform="translate(-341 -16) translate(341 16)"
          />
          <g transform="translate(-341 -16) translate(341 16) translate(3 6)">
            <mask id="jeezytkglb" fill="#fff">
              <use xlink:href="#jzznzg3wta" />
            </mask>
            <use fill="#000" fill-rule="nonzero" xlink:href="#jzznzg3wta" />
            <g :fill="color" mask="url(#jeezytkglb)">
              <path d="M0 0H24V24H0z" transform="translate(-3 -6)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: "#FFF",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },
};
</script>

<style scoped>
</style>