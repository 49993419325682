<template>
  <div class="passport-icon-container">
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19 20H5V4h14v16zM5 2c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H5zm12 14v2H7v-2h10zM11.995 5A5.003 5.003 0 0 1 17 10c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0 1 7 10c0-2.76 2.235-5 4.995-5zm.96 7h-1.91c.215.715.54 1.38.955 1.98.415-.6.74-1.265.955-1.98zm-2.94 0H8.54a3.993 3.993 0 0 0 2.165 1.78 7.825 7.825 0 0 1-.69-1.78zm5.445 0h-1.475a7.825 7.825 0 0 1-.69 1.78A4.015 4.015 0 0 0 15.46 12zM9.82 9H8.13c-.08.32-.13.655-.13 1s.05.68.13 1h1.69c-.04-.33-.07-.66-.07-1 0-.34.03-.67.07-1zm3.35 0h-2.34c-.045.325-.08.66-.08 1 0 .34.035.67.08 1h2.34c.045-.33.08-.66.08-1 0-.34-.035-.675-.08-1zm2.7 0h-1.69c.04.33.07.66.07 1 0 .34-.03.67-.07 1h1.69c.08-.32.13-.655.13-1s-.05-.68-.13-1zm-2.575-2.78c.3.555.53 1.155.69 1.78h1.475a4.015 4.015 0 0 0-2.165-1.78zM12 6.02c-.415.6-.74 1.265-.955 1.98h1.91A7.043 7.043 0 0 0 12 6.02zm-1.295.2c-.92.315-1.685.95-2.165 1.78h1.475c.16-.625.39-1.225.69-1.78z"
          :fill="color" />
      </g>
    </svg>
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "PassportIconCockpit",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },

    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },
  }

}
</script>

<style scoped lang="scss">
.passport-icon-container {
  display: flex;
  justify-content: center;
}
</style>
