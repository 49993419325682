// Regular expression to match "YYYY-MM-DD" format
export const IS_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;

export const IS_HEX_COLOR_REGEX = /^[0-9a-fA-F]{6}$/;

export const HAS_NO_SPACES = /^\S*$/;

// Regex pattern to match text within curly braces
export const BETWEEN_CURLY_BRACKETS = /\{([^,]+),\s*([^}]+)\}/g;

export const NUMBER_REGEX = /^-?\d+$/;

export const COMPANY_INFORMATION_SERVICE_DESCRIPTOR_CREDIT_COST_SPEC_KEYS_PATTERN =
  '^((?:(Search|CreateReport)-\\((?:(?:[A-Z]{2})|(?:[A-Z]{2}\\|.*))+\\))|DuratedCompanySearch|SearchList|Default)$';

export const VUE_COMPONENT_FILE_NAME_REGEX = /(\w+).vue$/;
export const JS_FILE_NAME_REGEX = /(\w+).js$/;
export const PATH_ELEMENTS_REGEX = /\w+/g;
