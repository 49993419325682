<template>
    <!--SVG icon-->
    <svg
      :height="height"
      :width="width"
      :class="arrowClass"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path
        :fill="arrowColor"
        d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"/>
    </svg>
</template>

<script>
  export default {
    name: "UpDownArrows",

    props: {
      isUpArrow: {
        type: Boolean,
        required: false,
      },
      height: {
        type: String,
        required: false,
        default: '24'
      },
      width: {
        type: String,
        required: false,
        default: '24'
      }
    },

    data() {
      return {
        arrowColor: '#afafaf',
        enabledColor: '#757575',
        disabledColor: '#afafaf',
      };
    },

    watch: {
      isUpArrow() {
        this.setArrowDirection();
      }
    },

    computed: {
      arrowClass() {
        return {
          'up-arrow': this.isUpArrow,
          'down-arrow': !this.isUpArrow
        }
      }
    },

    methods: {
      setArrowDirection() {
        if (typeof this.isUpArrow !== 'undefined') {
          this.arrowColor = this.enabledColor;
        } else {
          this.arrowColor = this.disabledColor;
        }
      }
    },

    mounted() {
      this.setArrowDirection();
    }
  }
</script>

<style scoped>
  .up-arrow {
    transform: scaleY(1);
  }
  .down-arrow {
    transform: scaleY(-1);
  }
</style>