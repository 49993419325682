<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0L24 0 24 24 0 24z"
                    transform="translate(-154.000000, -280.000000) translate(0.000000, 216.000000) translate(20.000000, 10.000000) translate(0.000000, 44.000000) translate(10.000000, 10.000000) translate(124.000000, 0.000000)"
                  />
                  <path
                    :fill="color"
                    fill-rule="nonzero"
                    d="M6 8c1.864 0 3.43 1.275 3.874 3H21c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1s-1-.448-1-1v-2h-1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2H9.874c-.445 1.726-2.01 3-3.874 3-2.21 0-4-1.79-4-4s1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
                    transform="translate(-154.000000, -280.000000) translate(0.000000, 216.000000) translate(20.000000, 10.000000) translate(0.000000, 44.000000) translate(10.000000, 10.000000) translate(124.000000, 0.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';
export default {
  name: "ArrowDown",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY,
    },

    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },

    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },
};
</script>

<style scoped></style>
