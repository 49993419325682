<template>
  <!--SVG icon-->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="prefix__a"
        d="M6.667 0L5.492 1.175 10.142 5.833 0 5.833 0 7.5 10.142 7.5 5.492 12.158 6.667 13.333 13.333 6.667z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0L20 0 20 20 0 20z" />
      <g transform="translate(3.333 3.333)">
        <mask id="prefix__b" fill="#fff">
          <use xlink:href="#prefix__a" />
        </mask>
        <use fill="#000" fill-rule="nonzero" xlink:href="#prefix__a" />
        <g :fill="color" mask="url(#prefix__b)">
          <path d="M0 0H20V20H0z" transform="translate(-3 -3)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "RightArrowIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    }
  }
};
</script>

<style scoped>
</style>