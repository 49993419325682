import { v4 as uuid } from 'uuid';
import axios from 'axios';
import ApiError from './errors/error';
import DeprecationError from './errors/deprecation';
import TwoFactorCodeSent from './errors/2fa';
import LockedTryAgain from './errors/locked_try_again';

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
// NewBanking API JSONRpc 2.0 request constructor
//
// Usage
//
//   import ApiRequest from '@packages/api-request'
//   response = await new ApiRequest('https://api.com', 'ping').request();
//   // Do something with the response
//
export default class NbApiRequest {
  constructor(url, method, params) {
    this.url = url;
    this.method = method;
    this.constructParams(params);
  }

  constructParams(params) {
    this.params = Object.assign({}, params || {});

    this.params = {
      method: this.method,
      params: this.params,
      jsonrpc: '2.0',
    }
  }

  async request(options) {
    if (!options) options = {};

    this.params.id = uuid();
    let response = await axios.post(this.url, this.params);

    // If this is an async request start polling for the result
    if (this.params.params.async) response = await this.poll(response.data.result.futureId);

    // Check the response against known types of errors.
    if (ApiError.match(response)) return Promise.reject(new ApiError(response));
    if (DeprecationError.match(response)) {
      window.console.warn(`${this.params.method} is deprecated`);
    }
    if (TwoFactorCodeSent.match(response)) return Promise.reject(new TwoFactorCodeSent(response));

    // If the resource is locked, retry the request in 1 second
    if (LockedTryAgain.match(response)) {
      await sleep(1);
      response = this.request(options);
    }

    if (options.fullResponseObject) {
      return Promise.resolve(response);
    } else {
      return Promise.resolve(response.data.result);
    }
  }

  async poll(futureId) {
    this.params.method = 'getFuture';
    this.params.params = { futureId };

    let response = { data: { result: { status: 'Processing' } } };
    while (response.data.result.status === 'Processing') {
      await this.sleep(1000);
      response = await this.request({ fullResponseObject: true });
    }

    return Promise.resolve(response);
  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
