import Vue from 'vue';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useNotifierStore } from '@packages/stores';
import { useI18n } from '@packages/translations';

function cleanSensitiveDataFn(payloadObj) {
  const objectKeys = Object.keys(payloadObj);
  for (let i = 0; i < objectKeys.length; i++) {
    const prop = objectKeys[i];
    if (prop === 'personToken' || prop === 'userData') {
      delete payloadObj[prop];
    } else if (
      typeof payloadObj[prop] === 'string' &&
      (payloadObj[prop].includes('personToken') || payloadObj[prop].includes('userData'))
    ) {
      try {
        const jsonProp = cleanSensitiveDataFn(JSON.parse(payloadObj[prop]));
        payloadObj[prop] = JSON.stringify(jsonProp);
      } catch (error) {
        payloadObj[prop] = 'Property contained sensitive information and has been removed';
      }
    } else if (payloadObj[prop] !== null && typeof payloadObj[prop] === 'object') {
      payloadObj[prop] = cleanSensitiveDataFn(payloadObj[prop]);
    }
  }

  return payloadObj;
}

export default {
  computed: {},

  methods: {
    async notify(notification, translationParams) {
      const uiNotification = {
        ...notification,
        ...(translationParams && { translationParams }),
      };

      const notifierStore = useNotifierStore();
      notifierStore.submitNotification(uiNotification);
      if (notification.payload) {
        let notificationMessageTranslationKey = 'exceptions.defaultError';

        if (notification.message && notification.message.scope && notification.message.code) {
          if (notification.message.context && notification.message.scope && notification.message.code) {
            notificationMessageTranslationKey = `${notification.message.context}.exceptions.${notification.message.scope}.${notification.message.code}`;
          } else if (notification.message.scope && notification.message.code) {
            notificationMessageTranslationKey = `exceptions.${notification.message.scope}.${notification.message.code}`;
          } else if (notification.scope) {
            notificationMessageTranslationKey = `exceptions.${notification.scope}.defaultError`;
          } else {
            notificationMessageTranslationKey = notification.message;
          }
        } else if (
          notification.message &&
          notification.message.response &&
          notification.message.response.data &&
          notification.message.response.data.error &&
          notification.message.response.data.error.data &&
          notification.message.response.data.error.data.code
        ) {
          notificationMessageTranslationKey = `exceptions.${notification.message.response.data.error.data.scope}.${notification.message.response.data.error.data.code}`;
        }
        if (
          notification.payload.response &&
          notification.payload.response.data &&
          notification.payload.response.data.error &&
          notification.payload.response.data.error.data &&
          notification.payload.response.data.error.data.details
        ) {
          notification.details = notification.payload.response.data.error.data.details;
        }
        const i18n = useI18n();
        notification.message = i18n.t(notificationMessageTranslationKey, 'en-US', translationParams);

        notification = cleanSensitiveDataFn(notification);
        this.errorFunction(notification);
      }
    },

    removeNotification(notification) {
      const notifierStore = useNotifierStore();
      notifierStore.substractNotification(notification);
    },

    errorFunction(notification) {
      // This is the default error function, it will log the error and try to report to rollbar
      // It is recommended to pass your own error function when instantiating the
      // Notifier in your application.

      window.console.warn(
        'The error function should be set on the application level, when the package is loaded into Vue',
      );
      window.console.error(notification);

      if (Vue.prototype.$rollbar) {
        if (notification.payload) {
          Vue.prototype.$rollbar.error(
            notification.message,
            notification.details ? notification.details : notification.payload,
          );
        } else {
          Vue.prototype.$rollbar.error(notification.message, notification);
        }
      }
    },
  },
  cleanSensitiveData: cleanSensitiveDataFn,
};
