<template>
    <svg
      :width="width"
      :height="height"
      :viewBox="options.viewBox"
    >
        <defs>
            <path id="9puqtb9wma" d="M11.5 4.5C6.708 4.5 2.616 7.61.958 12c1.658 4.39 5.75 7.5 10.542 7.5s8.884-3.11 10.542-7.5c-1.658-4.39-5.75-7.5-10.542-7.5zm0 12.5c-2.645 0-4.792-2.24-4.792-5S8.855 7 11.5 7s4.792 2.24 4.792 5-2.147 5-4.792 5zm0-8c-1.59 0-2.875 1.34-2.875 3s1.284 3 2.875 3c1.59 0 2.875-1.34 2.875-3S13.091 9 11.5 9z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0L23 0 23 24 0 24z"/>
            <mask id="35tl43h2nb" fill="#fff">
                <use xlink:href="#9puqtb9wma"/>
            </mask>
            <use fill="#000" fill-rule="nonzero" xlink:href="#9puqtb9wma"/>
            <g :fill="color" mask="url(#35tl43h2nb)">
                <path d="M0 0H23V24H0z"/>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "ShowPasswordIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: () => {
          return '#757575';
        }
      },
      width: {
        type: String,
        required: false,
        default: () => {
          return '24';
        }
      },
      height: {
        type: String,
        required: false,
        default: () => {
          return '24';
        }
      },
      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 24 24' }
        }
      }
    }
  }
</script>

<style>

</style>