<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
  >  <defs>
      <path d="M14 0H2C.9 0 0 .9 0 2v12.01C0 15.11.9 16 2 16h16c1.1 0 2-.9 2-2V6l-6-6zM2 14.01V2h11v5h5v7.01H2z" id="a"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <g transform="translate(2 4)">
        <mask :id="`note-mask-${randomNumber}`" fill="#fff">
          <use xlink:href="#a"/>
        </mask>
        <use fill="#000" fill-rule="nonzero" xlink:href="#a"/>
        <g :mask="`url(#note-mask-${randomNumber})`" :fill="color" fill-rule="nonzero">
          <path d="M-2-4h24v24H-2z"/>
        </g>
      </g>
    </g>
  </svg>

</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "NoteIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },

    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },

    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
  },

  data() {
    return {
      randomNumber: null
    }
  },

  created() {
    this.randomNumber = Math.floor((Math.random() * 1000) + 1);
  }
};
</script>

<style scoped></style>
