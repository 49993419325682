<template>
  <div class="icon-container">
    <svg
      role="presentation"
      :width="width"
      :height="height"
      viewBox="0 -5 30 30"
      class="info-icon-small nohover "
      :fill="color"
    >
      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
      
    </svg>
  </div>
</template>

<script>
    export default {
        name: "WarningIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: '#D32F2F'
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .icon-container {
        display: flex;
        justify-content: center;
    }
    .nohover {
    display: inline-block;
    }

</style>