import { Configuration } from 'rollbar';

interface EnvSpecificConfigs {
  [key: string]: Configuration
}

const envSpecificConfigs: EnvSpecificConfigs = {
  "test": {
    "enabled": false,
    "autoInstrument": false,
    "captureUncaught": true,
    "captureUnhandledRejections": true,
    "environment": "test",
  },

  "local": {
    "enabled": false,
    "autoInstrument": false,
    "captureUncaught": true,
    "captureUnhandledRejections": true,
    "environment": "local",
  },

  "dev": {
    "enabled": false,
    "autoInstrument": false,
    "captureUncaught": true,
    "verbose": true,
    "captureUnhandledRejections": true,
    "environment": "development",
    "maxItems": 5,
    "itemsPerMinute": 2
  },

  "stage": {
    "autoInstrument": false,
    "captureUncaught": true,
    "verbose": true,
    "captureUnhandledRejections": true,
    "environment": "stage",
  },

  "prod": {
    "autoInstrument": false,
    "captureUncaught": true,
    "verbose": true,
    "captureUnhandledRejections": true,
    "environment": "production",
  }
}

const baseConfig: Configuration = {
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  environment: process.env.NODE_ENV,
  payload: {
    client: {
      javascript: {
        guess_uncaught_frames: true
      }
    },
    server: {
      root: 'webpack:///./'
    }
  }
}

export {
  envSpecificConfigs,
  baseConfig
}
