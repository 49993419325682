import { useApiCallsStore } from '@packages/stores';

export default {
  methods: {
    async call(apiName, methodOptions) {
      const apiCallsStore = useApiCallsStore();

      return await apiCallsStore.apiCall({
          apiName,
          apiParams: methodOptions && methodOptions.apiParams ? methodOptions.apiParams : null,
          errorHandler: methodOptions && methodOptions.errorHandler ? methodOptions.errorHandler : false
      });
    },

    async customCall(apiName, methodOptions) {
      const apiCallsStore = useApiCallsStore();

      return await apiCallsStore[apiName]({
        apiParams: methodOptions && methodOptions.apiParams ? methodOptions.apiParams : null,
        errorHandler: methodOptions && methodOptions.errorHandler ? methodOptions.errorHandler : false
      });
    }
  }
}
