/* eslint-disable */
import Vue from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import _ from 'lodash';


const defaultTranslations = {
  'en-US': require('./translations/en-US.json'),
  'da-DK': require('./translations/da-DK.json'),
  'sv-SE': require('./translations/sv-SE.json'),
  'de-DE': require('./translations/de-DE.json'),
  'fi-FI': require('./translations/fi-FI.json'),
  'fr-FR': require('./translations/fr-FR.json'),
};

function getCookie (name: string) {
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escapeString(name) + '=([^;]*)'));
  if (!match) return null;

  return decodeURIComponent(match[1]);
}

function escapeString(escapeString: string) {
  return escapeString.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
}

function getConsentCookie () {
  let cookieConsent = getCookie('CookieConsent');

  if (cookieConsent) {
    // Add double quotes to initial key for json parsing
    let cookieToDecode = cookieConsent.replace('stamp', '"stamp"');
    // Add double quotes to remaining keys for json parsing
    let objectKeys = cookieToDecode.match(/,[\w\d]+:/g);
    if (objectKeys) {
      let mappedObjectKeys = objectKeys.map(function(value){return value.substring(1, value.length-1)});
      for (let i = 0; i < mappedObjectKeys.length; i++) {
        cookieToDecode = cookieToDecode.replace(mappedObjectKeys[i], `"${mappedObjectKeys[i]}"`);
      }
      // Replace single quotes to double quotes for json parsing
      cookieToDecode = cookieToDecode.replace(/'/g, '"');
      const decodedCookie = JSON.parse(cookieToDecode)
      return decodedCookie;
    }
  }

  return null;
}

function setLocaleCookie (value: string) {
  if (isLocalStorageAvailable) {
    const cookieConsent = getConsentCookie();
    const parentAppURL = window.location.pathname;
    const cookieRequireAppList = ['/login/', '/register/']
    const isCookieConsentRequiredByApp = cookieRequireAppList.includes(parentAppURL);
    if (!isCookieConsentRequiredByApp || (isCookieConsentRequiredByApp && cookieConsent && cookieConsent.preferences)) {
      try {
        window.localStorage.setItem('nb_locale', value);
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        window.localStorage.removeItem('nb_locale');
      } catch (error) {
        console.error(error)
      }
    }
  }
}

interface Options {
  useLocaleCookieManagement?: boolean;
  locale?: string;
  fallbackLocale?: string;
  locales?: Record<string, object>;
  messages?: Record<string, any>;
  onTranslationNotFound?: (locale: string, key: string) => void;
}

class Translator {
  static _instance:  Translator | null = null;
  static _i18n: VueI18n | null = null;

  constructor () {
    if (Translator._instance) {
      throw new Error('Cannot create more than one instance of this class');
    }
    Translator._instance = this;
  }

  static getTranslatorInstance () {
    if (!Translator._instance) {
      Translator._instance = new Translator();
    }

    return Translator._instance;
  }

  static _createI18nInstance (options: Options) {
    Vue.use(VueI18n);

    Translator._i18n = new VueI18n({
      locale: options.locale || 'en-US',
      fallbackLocale: options.fallbackLocale || 'en-US',
      messages: options?.messages || defaultTranslations,
      missing: (locale, key) => {
        // If we don't have the translation, report this to rollbar
        const err = `Missing translation (${locale}): ${key}`;
        window.console.warn(err);
        if (typeof Vue.rollbar !== 'undefined'){
          if (locale && key) {
            // Commented out to mute the rollbar warnings until we solve the current rollbar reports issue.
            // Vue.rollbar.warning(err);
          }
          if (options.onTranslationNotFound) {
            options.onTranslationNotFound(locale, key);
          }
        }

        return key;
      }
    });
  }

  static _setLocale (options: Options) {
    if (!Translator._i18n) {
      throw new Error('VueI18n instance not found. Please call _createI18nInstance() before calling _setLocale()');
    }

    Vue.locales = options.locales;
    Vue.prototype.locales = options.locales;

    // Determine best matching locale
    let locale = isLocalStorageAvailable ? window.localStorage.getItem('nb_locale') : null;
    if (!locale) {
      // Because IE11 doesn't support the URL we don't support URL locale in IE11.
      try {
        locale = new URL(window.location.href).searchParams.get('locale');
      } catch (e) {
        window.console.warn("This browser doesn't support locale based on the URL.", e);
      }

      // If the browser has a locale, use that
      if (navigator && navigator.language) locale = navigator.language;
    }

    if (locale) {
      // Validate locale
      const validLocales = Object.keys(options.locales || {});
      if (locale &&validLocales.indexOf(locale) <= 0) locale = validLocales[0];

      setLocaleCookie(locale);

      // Use found locale
      Translator._i18n.locale = locale;
    }
  }

  static _mergeLocaleMessages (options: Options) {
    if (!Translator._i18n) {
      throw new Error('VueI18n instance not found. Please call _createI18nInstance() before calling _mergeLocaleMessages()');
    }
    if (options.locales) {
      for (let locale in options.locales) {
        Translator._i18n.mergeLocaleMessage(locale, options.locales[locale] as LocaleMessageObject);
      }
    }
  }

  getI18n () {
    return Translator._i18n;
  }

  updateI18n (options: Options) {
    if (!Translator._i18n) {
      throw new Error('VueI18n instance not found. Please call initI18n() before calling updateI18n()');
    }
    if (options.locale) {
      Translator._i18n.locale = options.locale;
    }
    if (options.fallbackLocale) {
      Translator._i18n.fallbackLocale = options.fallbackLocale;
    }
    if (options.messages) {
      for (let locale in options.messages) {
        Translator._i18n.setLocaleMessage(locale, options.messages[locale]);
      }
    }
    if (options.locales) {
      Translator._mergeLocaleMessages(options);
    }
    if (options.useLocaleCookieManagement) {
      Translator._setLocale(options);
    }
  }

  initI18n (options: Options) {
    if (!Translator._i18n) {
      Translator._createI18nInstance(options);
    }
    Translator._mergeLocaleMessages(options);

    if (options.useLocaleCookieManagement) {
      Translator._setLocale(options);
    }

    return Translator._i18n;
  }
}


export const useI18n = (options: Options = {}): VueI18n => {
  const instance = Translator.getTranslatorInstance();
  const i18n = instance.getI18n() || instance.initI18n(options) as VueI18n;

  if (!_.isEmpty(options)) {
    instance.updateI18n(options);
  }

  return i18n;
};
