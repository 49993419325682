import { tooManyRequestsConstants } from '@packages/constants';
const { MINIMUM_TOKEN_AMOUNT_REQUIRED_TO_CONTINUE_WORKING } = tooManyRequestsConstants;


/**
 * Calculates the amount of time in seconds it will take for the token bucket to replenish
 * @param {Object} tokenBucketDetails - The token bucket details object
 * @param {number} tokenBucketDetails.rate - how many tokens are added to the token bucket per second
 * @param {number} tokenBucketDetails.available - The amount of tokens available in the token bucket 
 * @returns {number} - The amount of time in seconds it will take for the token bucket to replenish 
 */
export const calculateReplenishmentTime = (tokenBucketDetails) => {
  const { rate, available } = tokenBucketDetails;

  const tokensNeeded = MINIMUM_TOKEN_AMOUNT_REQUIRED_TO_CONTINUE_WORKING - available;
  const secondsToReplenish = Math.ceil(tokensNeeded / rate);
  
  return secondsToReplenish;
};