import initFeatureFlags from '@packages/feature-flags-tools';
import posthog, { getPosthogInstance } from '@packages/posthog';
import Vue from 'vue';
import Rollbar from '@packages/rollbar';
import packageJson from '../../../package.json';
import { sessionTabsCountHandler } from '@packages/api';

/* eslint-disable no-undef */
/**
 * After checking localstorage functionality, adds feature flags tools and initializes the app
 * The order of initializers is important, as some of them depend on others.
 * @returns {null} - Does not return anything
 */
async function checkLocalStorageAndRunInits () {
  if (isLocalStorageAvailable && isBrowserCompatible) {
    Vue.use(posthog);
    const posthogInstance = getPosthogInstance();
    Vue.use(Rollbar);
    Vue.rollbar.configure({
      payload: {
        ...(posthogInstance && {posthogSessionId: posthogInstance.get_session_id()}),
        client: {
          javascript: {
            code_version: packageJson.version,
          }
        }
      }
    })

    initFeatureFlags({
      rollbarInstance: Vue.rollbar,
      posthogInstance
    });

    /**
     * We're using the 'eager' mode here to force webpack to
     * include the appInitializer module in the main bundle.
     * This is because we kept on getting a "chunk not found" error
     * from production builds when the appInitializer module was in a separate chunk.
     *
     *  https://webpack.js.org/api/module-methods/#magic-comments
     */
    const { default: initApp } = await import(/* webpackMode: "eager" */ './appInitializer');
    initApp();
  }
}

sessionTabsCountHandler();
checkLocalStorageAndRunInits()
