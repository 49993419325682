export default class ApiError {
  constructor(response) {
    this.response = response;
    this.nbError = true;
  }

  status() {
    return this.response.status;
  }

  display() {
    return true;
  }

  code() {
    if (this.response && this.response.data && this.response.data.error) {
      let code = null;
      if (this.response.data.error.code) {
        code = this.response.data.error.code;
      }
      if (this.response.data.error.data) {
        code = this.response.data.error.data.code;
      }
      return code;
    }

    return JSON.stringify(this.response);
  }

  scope() {
    if (this.response && this.response.data && this.response.data.error) {
      let scope = null;
      if (this.response.data.error.scope) {
        scope = this.response.data.error.scope;
      }
      if (this.response.data.error.data) {
        scope = this.response.data.error.data.scope;
      }
      return scope;
    }

    return JSON.stringify(this.response);
  }

  fatal() {
    return [
      'InvalidPersonToken',
      'PersonSessionExpired',
      'PersonHasTooManyRequests',
    ].includes(this.code());
  }

  static match(response) {
    return response.data.error || !response.data.result;
  }
}
