<template>
    <svg
      :width="width"
      :height="height"
      :viewBox="options.viewBox"
    >
        <defs>
            <path id="wp05sy1dqa" d="M11.5 7c2.645 0 4.792 2.24 4.792 5 0 .65-.125 1.26-.345 1.83l2.798 2.92c1.447-1.26 2.587-2.89 3.287-4.75-1.658-4.39-5.75-7.5-10.542-7.5-1.341 0-2.625.25-3.814.7l2.07 2.16C10.292 7.13 10.877 7 11.5 7zM1.917 4.27l2.185 2.28.44.46C2.953 8.3 1.707 10.02.959 12c1.658 4.39 5.75 7.5 10.542 7.5 1.485 0 2.904-.3 4.197-.84l.403.42L18.908 22l1.217-1.27L3.134 3 1.917 4.27zm5.3 5.53l1.485 1.55c-.048.21-.077.43-.077.65 0 1.66 1.284 3 2.875 3 .21 0 .422-.03.623-.08l1.485 1.55c-.642.33-1.35.53-2.108.53-2.645 0-4.792-2.24-4.792-5 0-.79.192-1.53.508-2.2zm4.13-.78l3.018 3.15.02-.16c0-1.66-1.285-3-2.875-3l-.163.01z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0h23v24H0V0zm0 0h23v24H0V0zm0 0h23v24H0V0zm0 0h23v24H0V0z"/>
            <mask id="a1ik25uxnb" fill="#fff">
                <use xlink:href="#wp05sy1dqa"/>
            </mask>
            <use :fill="color" fill-rule="nonzero" xlink:href="#wp05sy1dqa"/>
            <g fill="#757575" mask="url(#a1ik25uxnb)">
                <path d="M0 0H23V24H0z"/>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "HidePasswordIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: () => {
          return '#757575';
        }
      },
      width: {
        type: String,
        required: false,
        default: () => {
          return '24';
        }
      },
      height: {
        type: String,
        required: false,
        default: () => {
          return '24';
        }
      },
      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 24 24' }
        }
      }
    }
  }
</script>

<style>

</style>