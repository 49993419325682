<template>
  <svg :width="width" :height="height" :viewBox="options.viewBox">
    <defs>
        <path id="wueuv02nta" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g transform="translate(-430 -368) translate(0 100) translate(410 76) translate(10 100) translate(0 79) translate(10 13)">
                                <path d="M0 0L24 0 24 24 0 24z"/>
                                <mask id="pf2s0ax1mb" fill="#fff">
                                    <use xlink:href="#wueuv02nta"/>
                                </mask>
                                <use fill="#000" fill-rule="nonzero" xlink:href="#wueuv02nta"/>
                                <g :fill="color" mask="url(#pf2s0ax1mb)">
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

</template>

<script>
export default {
  name: "CircleCheckIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: "#4CAF50",
    },
    width: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
    height: {
      type: Number,
      required: false,
      default: () => {
        return 24;
      },
    },
      options: {
      type: Object,
      required: false,
      default: () => {
        return { viewBox: '0 0 24 24' }
      }
    }
  },
};
</script>