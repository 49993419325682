import { REPORT_TYPES } from "./reports";
interface IsoCountry {
  name: string;
  iso2: string;
}

interface CountriesPerProvider {
  [serviceId: string]: IsoCountry[];
}

export interface CompanyInfoProviderSelectionItem {
  name: string;
  configName: string;
  serviceId: string;
  reports?: string[];
}

interface CompanyInfoServiceErrors {
  [errorCode: string]: string;
}

interface CompanyInfoProvidersSelectionItems {
  [serviceId: string]: CompanyInfoProviderSelectionItem;
}

interface CompanyInfoServiceCreditCostPrefixes {
  SEARCH: string;
  CREATE_REPORT: string;
}

interface CompanyInfoServiceNames {
  CVR: string;
  OPEN_CORPORATES: string;
  CREDIT_SAFE: string;
  ALMA_TALENT: string;
  COMPASS: string;
}

interface CompanyInfoProviders {
  CVR: string;
  OPENCORPORATES: string;
  CREDITSAFE: string;
  ALMA_TALENT: string;
  COMPASS: string;
}

export type CompanyInformationProvider = (typeof COMPANY_INFORMATION_PROVIDERS)[keyof typeof COMPANY_INFORMATION_PROVIDERS];

export const CVR_SERVICE_ID = '5a1d0b01-8112-4c1d-9a8d-68e70ec6ad15';
export const CREDITSAFE_SERVICE_ID = 'c458c697-69ec-41ff-b17a-69c72fba1a55';
export const OPENCORPORATES_SERVICE_ID = '4837264c-6810-4cc5-aa28-5eea86d45093';
export const ALMA_TALENT_SERVICE_ID = '900a2946-84f0-4074-94b6-223b23381f7c';
export const COMPASS_SERVICE_ID = '288d6d84-3570-48ea-b0a0-f4f12d8422b7';

export const COUNTRIES_PER_PROVIDER: CountriesPerProvider = {
  [COMPASS_SERVICE_ID]: [
    {
      name: 'Austria',
      iso2: 'AT'
    }
  ],
  [ALMA_TALENT_SERVICE_ID]: [
    {
      name: 'Finland',
      iso2: 'FI'
    }
  ],
  [CVR_SERVICE_ID]: [
    {
      name: 'Denmark',
      iso2: 'DK'
    }
  ],
  [CREDITSAFE_SERVICE_ID]: [
    {
      name: 'Belgium',
      iso2: 'BE'
    },
    {
      name: 'Denmark',
      iso2: 'DK'
    },
    {
      name: 'Germany',
      iso2: 'DE'
    },
    {
      name: 'France',
      iso2: 'FR'
    },
    {
      name: 'United Kingdom',
      iso2: 'GB'
    },
    {
      name: 'Ireland',
      iso2: 'IE'
    },
    {
      name: 'Italy',
      iso2: 'IT'
    },
    {
      name: 'Japan',
      iso2: 'JP'
    },
    {
      name: 'Luxembourg',
      iso2: 'LU'
    },
    {
      name: 'Netherlands',
      iso2: 'NL'
    },
    {
      name: 'Norway',
      iso2: 'NO'
    },
    {
      name: 'Sweden',
      iso2: 'SE'
    },
    {
      name: 'United States',
      iso2: 'US'
    }
  ]
};

export const COMPANY_INFORMATION_PROVIDERS: CompanyInfoProviders = {
  CVR: 'cvr',
  CREDITSAFE: 'creditsafe',
  OPENCORPORATES: 'opencorporates',
  ALMA_TALENT: 'almatalent',
  COMPASS: 'compass'
} as const;

export const COMPANY_INFORMATION_SERVICE_ERRORS: CompanyInfoServiceErrors = {
  '400': 'InvalidInput',
  '402': 'NotEnoughCredits',
  '403': 'InvalidServiceId',
  '404': 'notFound',
  'defaultError': 'defaultError',
  'actionForbidden': 'actionForbidden'
};

export const COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_CVR: CompanyInfoProviderSelectionItem = {
  name: 'CVR',
  configName: COMPANY_INFORMATION_PROVIDERS.CVR,
  serviceId: CVR_SERVICE_ID
};

export const COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_CREDITSAFE: CompanyInfoProviderSelectionItem = {
  name: 'CreditSafe',
  configName: COMPANY_INFORMATION_PROVIDERS.CREDITSAFE,
  serviceId: CREDITSAFE_SERVICE_ID,
  reports: [
    REPORT_TYPES.CreditRatingReport
  ]
};

export const COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_OPENCORPORATES: CompanyInfoProviderSelectionItem = {
  name: 'OpenCorporates',
  configName: COMPANY_INFORMATION_PROVIDERS.OPENCORPORATES,
  serviceId: OPENCORPORATES_SERVICE_ID
};

export const COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_ALMA_TALENT: CompanyInfoProviderSelectionItem = {
  name: 'Alma Talent',
  configName: COMPANY_INFORMATION_PROVIDERS.ALMA_TALENT,
  serviceId: ALMA_TALENT_SERVICE_ID,
  reports: [
    REPORT_TYPES.CompanyRegistrationCertificate,
    REPORT_TYPES.FinancialStatement,
    REPORT_TYPES.ArticlesOfAssociation
  ]
};

export const COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_COMPASS: CompanyInfoProviderSelectionItem = {
  name: 'Compass',
  configName: COMPANY_INFORMATION_PROVIDERS.COMPASS,
  serviceId: COMPASS_SERVICE_ID,
  reports: [
    REPORT_TYPES.BeneficialOwnersExcerpt,
    REPORT_TYPES.CompanyRegisterExcerpt,
  ]
};

export const COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS: CompanyInfoProvidersSelectionItems = {
  [CVR_SERVICE_ID]: COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_CVR,
  [CREDITSAFE_SERVICE_ID]: COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_CREDITSAFE,
  [OPENCORPORATES_SERVICE_ID]: COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_OPENCORPORATES,
  [ALMA_TALENT_SERVICE_ID]: COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_ALMA_TALENT,
  [COMPASS_SERVICE_ID]: COMPANY_INFORMATION_PROVIDERS_SELECTION_ITEMS_COMPASS
};

export const COMPANY_INFORMATION_SERVICE_CREDIT_COST_SPEC_PREFIXES: CompanyInfoServiceCreditCostPrefixes = {
  SEARCH: 'Search-',
  CREATE_REPORT: 'CreateReport-'
};

export const COMPANY_INFORMATION_SERVICE_NAMES: CompanyInfoServiceNames = {
  CVR: 'Virk Data CVR',
  OPEN_CORPORATES: 'OpenCorporates',
  CREDIT_SAFE: 'CreditSafe',
  ALMA_TALENT: 'Alma Talent',
  COMPASS: 'Compass'
};

export const COMPANY_INFORMATION_SERVICE_TYPE = 'CompanyInformationService';
