
module.exports = {
  construct(response) {
    let responseObj = response;

    if (response.data) {
      responseObj = response.data;

      if (response.data.error) {
        responseObj = response.data.error;
      }
    }

    responseObj.nbError = true;

    return responseObj;
  },

  match(response) {
    return response.data.error || !response.data.result;
  },
}
