<template>
    <svg
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         viewBox="0 0 130.2 130.2"
    >
        <circle
                :class="{ path: true, circle: true, static: isStatic }"
                fill="none"
                :stroke="color"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1" cy="65.1" r="62.1"
        />
        <polyline
                :class="{ path: true, check: true, static: isStatic }"
                fill="none"
                :stroke="color"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
    </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';

    export default {
        name: "SuccessIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: COLORS.PRIMARY
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 60
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 60
                }
            },
            isStatic: {
                type: Boolean,
                required: false,
                default: ()=> {
                    return false
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
            -webkit-animation: dash .9s ease-in-out;
            animation: dash .9s ease-in-out;
        }
        &.line {
            stroke-dashoffset: 1000;
            -webkit-animation: dash .9s .35s ease-in-out forwards;
            animation: dash .9s .35s ease-in-out forwards;
        }
        &.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check .9s .35s ease-in-out forwards;
            animation: dash-check .9s .35s ease-in-out forwards;
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @-webkit-keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @media all and (-ms-high-contrast:none)
    {
        *::-ms-backdrop,
        .path { stroke-dasharray: 0 } /* IE11 */
    }

    .static { stroke-dasharray: 0 }

</style>