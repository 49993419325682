<template>
  <div class="drivers-licence-icon-container">
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path 
          d="M20 5a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h16zm0 2H4v12h16V7zm-4.944 2a.83.83 0 0 1 .788.561L17 12.89v4.444c0 .306-.25.556-.556.556h-.555a.557.557 0 0 1-.556-.556v-.555H8.667v.555c0 .306-.25.556-.556.556h-.555A.557.557 0 0 1 7 17.333V12.89L8.156 9.56A.835.835 0 0 1 8.944 9zM9.5 13.444a.832.832 0 1 0 0 1.667.832.832 0 1 0 0-1.667zm5 0a.832.832 0 1 0 0 1.667.832.832 0 1 0 0-1.667zm.356-3.333H9.139l-.578 1.667h6.872l-.577-1.667z" 
          :fill="color"
        />
    </g>
  </svg>
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "DriverLicenseIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },

    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },
  }
}
</script>

<style scoped lang="scss">
.drivers-licence-icon-container {
  display: flex;
  justify-content: center;
}
</style>