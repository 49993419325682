<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20">
        <defs>
            <path id="tixucn9uva" d="M15.833 10.833L10.833 10.833 10.833 15.833 9.167 15.833 9.167 10.833 4.167 10.833 4.167 9.167 9.167 9.167 9.167 4.167 10.833 4.167 10.833 9.167 15.833 9.167z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0L20 0 20 20 0 20z"/>
            <mask :id="maskId" fill="#fff">
                <use xlink:href="#tixucn9uva"/>
            </mask>
            <g :fill="color" :mask="`url(#${maskId})`">
                <path d="M0 0H20V20H0z"/>
            </g>
        </g>
    </svg>

</template>

<script>
import { COLORS } from '@packages/web-resources';

  export default {
    name: "PlusIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: COLORS.PRIMARY
      },

      width: {
        type: Number,
        required: false,
        default: () => 20

      },

      height: {
        type: Number,
        required: false,
        default: () => 20
      },

      maskId: {
        type: String,
        required: false,
        default: 'plus-icon-01'
      },
    }
  }
</script>

<style scoped>

</style>