<template>
  <svg :width="width" :height="height" :viewBox="options.viewBox" version="1.1">
    <defs>
        <path 
          d="M21,10.12 L14.22,10.12 L16.96,7.3 C14.23,4.6 9.81,4.5 7.08,7.2 C4.35,9.91 4.35,14.28 7.08,16.99 C9.81,19.7 14.23,19.7 16.96,16.99 C18.32,15.65 19,14.08 19,12.1 L21,12.1 C21,14.08 20.12,16.65 18.36,18.39 C14.85,21.87 9.15,21.87 5.64,18.39 C2.14,14.92 2.11,9.28 5.62,5.81 C9.13,2.34 14.76,2.34 18.27,5.81 L21,3 L21,10.12 Z M12.5,8 L12.5,12.25 L16,14.33 L15.28,15.54 L11,13 L11,8 L12.5,8 Z" 
          id="path-1"></path>
    </defs>
    <g id="Icon/Expiry" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <use id="icon" :fill="color" fill-rule="nonzero" xlink:href="#path-1"></use>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "ExpiryIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: '#757575'
      },
      width: {
        type: String,
        required: false,
        default: '24'
      },
      height: {
        type: String,
        required: false,
        default: '24'
      },
      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 24 24' }
        }
      }
    }
  }
</script>

<style scoped>

</style>