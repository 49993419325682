<template>
  <div class="tax-number-icon-container">
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path d="M20 10V8h-4V4h-2v4h-4V4H8v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4h4zm-6 4h-4v-4h4v4z" :fill="color"/>
    </g>
  </svg>
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

export default {
  name: "TaxIdentificationIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },
    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },
  }
}
</script>

<style scoped lang="scss">
.tax-number-icon-container {
  display: flex;
  justify-content: center;
}
</style>