export const COLORS = {
  WHITE: '#fff',
  BLACK: '#000',
  ICON_COLOR: '#757575',
  PRIMARY: '#155EEF',
  'PRIMARY-25': '#F5F8FF',
  'PRIMARY-50': '#EFF4FF',
  'PRIMARY-100': '#D1E0FF',
  'PRIMARY-200': '#B2CCFF',
  'PRIMARY-300': '#84AdFF',
  'PRIMARY-400': '#528BFF',
  'PRIMARY-500': '#2970FF',
  'PRIMARY-600': '#155EEF',
  'PRIMARY-700': '#004EEB',
  'PRIMARY-800': '#0040C1',
  'PRIMARY-900': '#00359E',
  'PRIMARY-950': '#002266',

  'GRAY-25': '#FCFCFD',
  'GRAY-50': '#F8FAFC',
  'GRAY-100': '#EEF2F6',
  'GRAY-200': '#E3E8EF',
  'GRAY-300': '#CDD5DF',
  'GRAY-400': '#9AA4B2',
  'GRAY-500': '#697586',
  'GRAY-600': '#4B5565',
  'GRAY-700': '#364152',
  'GRAY-800': '#202939',
  'GRAY-900': '#121926',
  'GRAY-950': '#0D121C',

  DANGER: '#F04438',
  'ERROR-25': '#FFFBFA',
  'ERROR-50': '#FEF3F2',
  'ERROR-100': '#FEE4E2',
  'ERROR-200': '#FECDCA',
  'ERROR-300': '#FDA29B',
  'ERROR-400': '#F97066',
  'ERROR-500': '#F04438',
  'ERROR-600': '#D92D20',
  'ERROR-700': '#B42318',
  'ERROR-800': '#912018',
  'ERROR-900': '#7A271A',
  'ERROR-950': '#55160C',

  'WARNING-25': '#FFFCF5',
  'WARNING-50': '#FFFAEB',
  'WARNING-100': '#FEF0C7',
  'WARNING-200': '#FEDF89',
  'WARNING-300': '#FEC84B',
  'WARNING-400': '#FDB022',
  'WARNING-500': '#F79009',
  'WARNING-600': '#DC6803',
  'WARNING-700': '#B54708',
  'WARNING-800': '#93370D',
  'WARNING-900': '#7A2E0E',
  'WARNING-950': '#4E1D09',

  'SUCCESS-25': '#F6FEF9',
  'SUCCESS-50': '#ECFDF3',
  'SUCCESS-100': '#DCFAE6',
  'SUCCESS-200': '#ABEFC6',
  'SUCCESS-300': '#75E0A7',
  'SUCCESS-400': '#47CD89',
  'SUCCESS-500': '#17B26A',
  'SUCCESS-600': '#079455',
  'SUCCESS-700': '#067647',
  'SUCCESS-800': '#085D3A',
  'SUCCESS-900': '#074D31',
  'SUCCESS-950': '#053321'
};

export const FONT = {
  BODY_FONT_FAMILY: '"Inter", sans-serif'
}