<template>
  <div class="icon-container">
    <div v-if="isCompanyIdentity && userData.type === dataTypes.NAME" class="metarial-icon">
      <v-icon
        :size="size"
        :color="color"
      >
        mdi-domain

      </v-icon>
    </div>
    <div v-else-if="dataHandler && dataHandler.materialIconName" class="metarial-icon">
      <v-icon
        :size="size"
        :color="color"
      >
        {{ `mdi-${dataHandler.materialIconName}` }}
      </v-icon>
    </div>
    <Component
      v-else
      class="custom-component"
      :is="loadRenderer"
      :color="color"
      :width="width"
      :height="height"
      :options="options"
    />
  </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';
import { VUE_COMPONENT_FILE_NAME_REGEX } from '@packages/constants';
const iconFiles = import.meta.glob('./iconComponents/*.vue', { eager: true, import: 'default' });

const customIconComponents = Object.keys(iconFiles).reduce((acc, key) => {
  const componentName = key.match(VUE_COMPONENT_FILE_NAME_REGEX)[1]
  acc[componentName] = iconFiles[key];

  return acc;
}, {});

export default {
  name: "DynamicIconComponent",

  props: {
    userData: {
      type: Object,
      required: true,
      default: () => {}
    },

    color: {
      type: String,
      required: false,
      default: COLORS.PRIMARY
    },

    width: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    height: {
      type: Number,
      required: false,
      default: ()=> {
        return 24
      }
    },

    size: {
      type: Number,
      required: false,
      default: 24
    },

    isCompanyIdentity: {
      type: Boolean,
      required: false,
      default: false
    },

    options: {
      type: Object,
      required: false,
      default: () => {
        return { viewBox: '0 0 24 24' }
      }
    }
  },

  data() {
    return {
      dataHandler: null,
      dataTypes: {
        NAME: 'Name',
        PASSPORT: 'Passport'
      }
    }
  },

  computed: {
    loadRenderer() {
      if(this.userData.type === this.dataTypes.PASSPORT){
        return customIconComponents[`${this.userData.type}IconCockpit`];
      }

      return customIconComponents[`${this.userData.type}Icon`] ? customIconComponents[`${this.userData.type}Icon`] : customIconComponents[`DocumentIcon`];
    }
  },

  created(){
    if(this.userData){
      this.dataHandler = this.getHandler(this.userData);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-container{
  display: flex;
  align-items: center;
}

.metarial-icon {
  margin-top: 5px;
}

.custom-component {
  margin-top: 5px;
}
</style>
