<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
    >
        <defs>
            <path
                id="wxs90gcgwa"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
            />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g transform="translate(-20 -214) translate(10 100) translate(0 101) translate(10 13)">
                            <path d="M0 0L24 0 24 24 0 24z"/>
                            <mask id="apw375nw2b" fill="#fff">
                                <use xlink:href="#wxs90gcgwa"/>
                            </mask>
                            <use fill="#000" fill-rule="nonzero" xlink:href="#wxs90gcgwa"/>
                            <g :fill="color" mask="url(#apw375nw2b)">
                                <path d="M0 0H24V24H0z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';

    export default {
        name: "SuccessFilledIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: COLORS.PRIMARY
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 60
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 60
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
            -webkit-animation: dash .9s ease-in-out;
            animation: dash .9s ease-in-out;
        }
        &.line {
            stroke-dashoffset: 1000;
            -webkit-animation: dash .9s .35s ease-in-out forwards;
            animation: dash .9s .35s ease-in-out forwards;
        }
        &.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check .9s .35s ease-in-out forwards;
            animation: dash-check .9s .35s ease-in-out forwards;
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @-webkit-keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @media all and (-ms-high-contrast:none)
    {
        *::-ms-backdrop,
        .path { stroke-dasharray: 0 } /* IE11 */
    }

    .static { stroke-dasharray: 0 }

</style>