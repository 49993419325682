<template>
    <div class="icon-container">
        <svg
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             :width="width"
             :height="height"
             viewBox="0 0 24 24"
        >
            <defs>
                <path
                      id="pen__a"
                      d="M5 16.084V19h2.916l8.601-8.601-2.916-2.916L5 16.083zm13.773-7.94c.303-.304.303-.793 0-1.097l-1.82-1.82c-.304-.303-.793-.303-1.097 0l-1.423 1.424 2.916 2.916 1.424-1.423z"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z"/>
                <mask :id="maskId" fill="#fff">
                    <use xlink:href="#pen__a"/>
                </mask>
                <use
                     :fill="color"
                     fill-rule="nonzero"
                     xlink:href="#pen__a"/>
                <g
                   :fill="color"
                   :mask="`url(#${maskId})`"
                >
                    <path d="M0 0H24V24H0z"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { COLORS } from '@packages/web-resources';

    export default {
        name: "PenIcon",

        props: {
            color: {
                type: String,
                required: false,
                default: COLORS.PRIMARY
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 24
                }
            },
            maskId: {
                type: String,
                required: false,
                default: 'default'
            },
        }
    }
</script>

<style scoped lang="scss">

    @import '../assets/styles/iconHoverEffectStyle';

</style>
