<template>
    <svg :width="width" :height="height" :viewBox="options.viewBox">
        <defs>
            <circle id="prefix__a" cx="25" cy="25" r="25"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <use :fill="color" fill-opacity=".2" xlink:href="#prefix__a"/>
            <use fill="#E5E5E5" xlink:href="#prefix__a"/>
            <g transform="translate(14.048 6.19)">
                <path :fill="COLORS.PRIMARY" d="M.737.855C1.202.262 1.769-.023 2.435.001h16.979c.654.066 1.212.414 1.673 1.046.461.631.707 1.337.738 2.117v31.355c-.019.716-.265 1.359-.738 1.927-.472.568-1.03.891-1.673.97H2.353c-.585 0-1.123-.257-1.616-.772C.244 36.13-.001 35.4 0 34.457V3.108C.026 2.2.272 1.45.737.855z"/>
                <circle cx="10.913" cy="32.738" r="1.559" fill="#FFF"/>
                <path fill="#FFF" d="M1.667 4.762H20.374000000000002V29.705000000000002H1.667z"/>
            </g>
            <g fill-rule="nonzero">
                <path :fill="color" d="M2.326 0h31.063c1.284 0 2.325 1.046 2.325 2.337v5.327c0 1.291-1.041 2.337-2.325 2.337H18.786l-1.42 1.428L15.943 10H2.326C1.04 10.001 0 8.955 0 7.664V2.337C0 1.047 1.041 0 2.326 0z" transform="translate(7.143 16.905)"/>
                <path fill="#FFF" d="M8.533 2.857l-.138 1.28 1.256-.361.11.877-1.144.083.751 1.033-.764.421-.526-1.089-.46 1.083-.795-.415.744-1.033-1.138-.09.13-.87 1.23.362-.138-1.28h.882zm4.048 0l-.138 1.28 1.255-.361.112.877-1.145.083.75 1.033-.764.421-.525-1.089-.461 1.083-.795-.415.744-1.033-1.138-.09.132-.87 1.228.362-.138-1.28h.883zm3.81 0l-.139 1.28 1.256-.361.111.877-1.145.083.751 1.033-.764.421-.525-1.089-.462 1.083-.794-.415.744-1.033-1.138-.09.131-.87 1.229.362-.138-1.28h.882zm3.809 0l-.138 1.28 1.255-.361.112.877-1.145.083.75 1.033-.764.421-.525-1.089-.461 1.083-.795-.415.744-1.033-1.138-.09.132-.87 1.229.362-.139-1.28h.883zm3.81 0l-.139 1.28 1.256-.361.111.877-1.145.083.751 1.033-.764.421-.525-1.089-.462 1.083-.794-.415.744-1.033-1.138-.09.131-.87 1.229.362-.138-1.28h.882zm4.047 0l-.138 1.28 1.256-.361.11.877-1.144.083.75 1.033-.764.421-.525-1.089-.461 1.083-.795-.415.744-1.033-1.138-.09.132-.87 1.229.362-.138-1.28h.882z" transform="translate(7.143 16.905)"/>
            </g>
        </g>
    </svg>
</template>

<script>
import { COLORS } from '@packages/web-resources';

  export default {
    name: "SmsVerificationIcon",

    props: {
      color: {
        type: String,
        required: false,
        default: COLORS.PRIMARY
      },
      width: {
        type: String,
        required: false
      },
      height: {
        type: String,
        required: false
      },
      options: {
        type: Object,
        required: false,
        default: () => {
          return { viewBox: '0 0 50 50' }
        }
      }
    },

    created () {
      this.COLORS = COLORS;
    }
  }
</script>

<style scoped>

</style>