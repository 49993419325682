import axios from 'axios';
const request = axios.create({
  headers: { Pragma: 'no-cache' }
});

class AxiosRequest {
  constructor (mockResponses) {
    this.mockResponses = mockResponses;
  }

  /**
   * This method creates a new request object and calls the API or returns the mocked response
   * @param {object} params an object containing the following properties:
   * @param {string} params.url the url to call
   * @param {string} params.httpMethod the http method to use
   * @param {object} params.data the data to send
   * @param {object} params.headers the headers to send
   * @param {string} params.methodName the name of the method to call
   * @returns {Promise} a promise that resolves with the response data
   */
  fire (params) {
    const mockApiResponse = !!localStorage.getItem('mockApiResponse');

    // If we are mocking the API, return the mocked response
    if (mockApiResponse) {
      const {methodName} = params;

      try {
        const mockedResponse = this.mockResponses[methodName];

        return Promise.resolve(mockedResponse);
      } catch (err) {
        const error = new Error(`Could not find mock data for ${methodName}`);

        return Promise.reject(error);
      }
    }

    // If we are not mocking the API, call the API
    const { url, httpMethod, data, headers } = params;
    const method = httpMethod || 'GET';

    return request({
      url,
      method,
      data,
      headers
    }).then((response) => response.data);
  }
}

export default AxiosRequest;