import ApiError from './error';

export default class LockedTryAgain extends ApiError {
  code() {
    return 'LockedTryAgain';
  }

  scope() {
    return 'Common';
  }

  static match(response) {
    return response.data.error &&
           response.data.error.data.code === this.code();
  }
}
