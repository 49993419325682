<template>
    <div class="icon-container">
        <svg
            :width="width"
            :height="height"
            :viewBox="options.viewBox"
        >
            <g fill="none" fill-rule="evenodd">
                <path :fill="colors[1]" fill-rule="nonzero" d="M4.136 0h66.173c2.284 0 4.135 1.85 4.135 4.134v37.205c0 2.283-1.851 4.134-4.135 4.134H4.136C1.852 45.473 0 43.622 0 41.339V4.134C0 1.85 1.852 0 4.136 0z" transform="translate(.778 .764)"/>
                <path :fill="colors[3]" d="M0 32.718H74.444V41.036H0z" transform="translate(.778 .764)"/>
                <g>
                    <path :fill="colors[0]" d="M0 0H8.333V8.873H0z" transform="translate(.778 .764) translate(60.556 12.755)"/>
                    <path :fill="colors[2]" fill-rule="nonzero" d="M4.167.807c-1.151 0-2.084.91-2.084 2.033 0 1.123.933 2.033 2.084 2.033 1.15 0 2.083-.91 2.083-2.033 0-1.123-.932-2.033-2.083-2.033zm0 4.575C2.776 5.382 0 6.062 0 7.415v1.458h8.333V7.415c0-1.352-2.776-2.033-4.166-2.033z" transform="translate(.778 .764) translate(60.556 12.755)"/>
                </g>
                <path :fill="colors[4]" d="M23.889 8.318h19.227v1.664H23.89V8.318zm25.773 0h19.227v1.664H49.662V8.318zm0 16.637h19.227v1.663H49.662v-1.663zM23.889 12.477h19.227v1.664H23.89v-1.664zm0 4.16h19.227V18.3H23.89v-1.664zm0 8.318h19.227v1.663H23.89v-1.663z" transform="translate(.778 .764)"/>
                <g>
                    <path :fill="colors[0]" d="M0 0H16.667V18.3H0z" transform="translate(.778 .764) translate(3.889 8.318)"/>
                    <path :fill="colors[2]" fill-rule="nonzero" d="M8.333 1.664c-2.302 0-4.166 1.876-4.166 4.193 0 2.317 1.864 4.194 4.166 4.194 2.302 0 4.167-1.877 4.167-4.194s-1.865-4.193-4.167-4.193zm0 9.436C5.553 11.1 0 12.504 0 15.293V18.3h16.667v-3.007c0-2.789-5.552-4.193-8.334-4.193z" transform="translate(.778 .764) translate(3.889 8.318)"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "PassportIcon",

        props: {
            colors: {
                type: Array,
                required: false,
                default: () => ['#F4F4F4', '#AFAFAF','#757575','#D5D5D5','#FFFFFF']
            },
            width: {
                type: Number,
                required: false,
                default: ()=> {
                    return 110
                }
            },
            height: {
                type: Number,
                required: false,
                default: ()=> {
                    return 66
                }
            },
            options: {
                type: Object,
                required: false,
                default: () => {
                return { viewBox: '0 0 76 47' }
                }
            }
        }

    }
</script>

<style scoped lang="scss">
</style>
