<template>
  <div class="icon-container">
    <svg
        :height="height"
        viewBox="0 0 24 24"
        :width="width"
        :fill="color"
    >
      <g id="Track-changes-locked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M17.363,4.637 L16.094,5.906 C17.39,7.211 18.2,9.011 18.2,11 C18.2,14.978 14.978,18.2 11,18.2 C7.022,18.2 3.8,14.978 3.8,11 C3.8,7.328 6.545,4.304 10.1,3.863 L10.1,5.681 C7.544,6.113 5.6,8.327 5.6,11 C5.6,13.979 8.021,16.4 11,16.4 C13.979,16.4 16.4,13.979 16.4,11 C16.4,9.506 15.797,8.156 14.816,7.184 L13.547,8.453 C14.195,9.11 14.6,10.01 14.6,11 C14.6,12.989 12.989,14.6 11,14.6 C9.011,14.6 7.4,12.989 7.4,11 C7.4,9.326 8.552,7.931 10.1,7.526 L10.1,9.452 C9.56,9.767 9.2,10.334 9.2,11 C9.2,11.99 10.01,12.8 11,12.8 C11.99,12.8 12.8,11.99 12.8,11 C12.8,10.334 12.44,9.758 11.9,9.452 L11.9,2 L11,2 C6.032,2 2,6.032 2,11 C2,15.968 6.032,20 11,20 C15.968,20 20,15.968 20,11 C20,8.516 18.992,6.266 17.363,4.637 Z" id="Mask" fill="#D5D5D5" fill-rule="nonzero"></path>
        <path d="M22.625,13.8125 L21.9375,13.8125 L21.9375,12.4375 C21.9375,10.54 20.3975,9 18.5,9 C16.6025,9 15.0625,10.54 15.0625,12.4375 L15.0625,13.8125 L14.375,13.8125 C13.61875,13.8125 13,14.43125 13,15.1875 L13,22.0625 C13,22.81875 13.61875,23.4375 14.375,23.4375 L22.625,23.4375 C23.38125,23.4375 24,22.81875 24,22.0625 L24,15.1875 C24,14.43125 23.38125,13.8125 22.625,13.8125 Z M18.5,20 C17.74375,20 17.125,19.38125 17.125,18.625 C17.125,17.86875 17.74375,17.25 18.5,17.25 C19.25625,17.25 19.875,17.86875 19.875,18.625 C19.875,19.38125 19.25625,20 18.5,20 Z M20.63125,13.8125 L16.36875,13.8125 L16.36875,12.4375 C16.36875,11.261875 17.324375,10.30625 18.5,10.30625 C19.675625,10.30625 20.63125,11.261875 20.63125,12.4375 L20.63125,13.8125 Z" id="Mask" fill="#757575" fill-rule="nonzero"></path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MonitorDataLockedIcon",

  props: {
    color: {
      type: String,
      required: false,
      default: '#757575'
    },
    width: {
      type: Number,
      required: false,
      default: 24
    },
    height: {
      type: Number,
      required: false,
      default: 24
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/iconHoverEffectStyle';
</style>