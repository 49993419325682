import { FeatureFlagsTools } from '@new-banking/nb-common-lib';
import { PostHog } from 'posthog-js';

interface SetupInstances {
  posthogInstance: PostHog | void,
  rollbarInstance?: any
}

/**
 * Function to initialize feature flags tools using correct config.
 * @param {SetupInstances}  instances - The instances of posthog and rollbar
 * @param {boolean} waitForFirstLoad - Whether to wait for the first load of feature flags
 * @returns {null|Promise} - Does not return anything, or a promise if waiting for first load
 */
async function initFeatureFlags (
  {
    posthogInstance,
    rollbarInstance
  } : SetupInstances
) {

  FeatureFlagsTools.initAndGetFeatureFlagsToolsInstance({
    posthogInstance,
    rollbarInstance
  });
}

export default initFeatureFlags;
